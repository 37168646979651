import React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { AddBoxOutlined } from '@mui/icons-material';
import Header from '../../components/Header';

const SitesHeader = ({ isAdmin, handleOpenDialog, isSmallScreen }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection={isSmallScreen ? "row" : "row"}
      justifyContent={isSmallScreen ? "space-between" : "space-between"}
      alignItems="center"
      marginBottom="1rem"
      padding="0.5rem"
      backgroundColor={theme.palette.background.paper}
      borderRadius="15px"
    >
      <Box flex="1" display="flex" alignItems="center">
        <Header title="SITES" subtitle="View your sites"/>
      </Box>
      {isAdmin && (
        <Button
          onClick={handleOpenDialog}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.black,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <AddBoxOutlined sx={{ mr: "10px" }} />
          Add New Site
        </Button>
      )}
    </Box>
  );
};

export default SitesHeader;
