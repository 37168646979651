import React from 'react';
import { Box } from '@mui/material';
import YearlyBarChart from '../../charts/barCharts/YearlyChart';
import YearlyLineChart from '../../charts/lineCharts/YearlyChart';
import YearlyPieChart from '../../charts/pieCharts/YearlyChart';
import Loading from '../../components/Loading';

const YearlyContent = ({ selectedSensor, showLabels, processedData, chartIsLoading, error, isSmallScreen, tabValue }) => {

    if (!selectedSensor) {
        return <Loading />;
    }

    return (
        <>
            <Box height="auto"width={isSmallScreen ? "auto" : "100%"}>
                {selectedSensor ? (
                    <>
                        <YearlyBarChart
                            selectedMac={selectedSensor}
                            showLabels={showLabels}
                            isSmallScreen={isSmallScreen}
                            processedData={processedData}
                            chartIsLoading={chartIsLoading}
                            error={error}
                            tabValue={tabValue}
                        />
                        <YearlyLineChart
                            selectedMac={selectedSensor}
                            showLabels={showLabels}
                            isSmallScreen={isSmallScreen}
                            processedData={processedData}
                            chartIsLoading={chartIsLoading}
                            error={error}
                            tabValue={tabValue}
                        />
                        <YearlyPieChart
                            selectedMac={selectedSensor}
                            showLabels={showLabels}
                            isSmallScreen={isSmallScreen}
                            processedData={processedData}
                            chartIsLoading={chartIsLoading}
                            error={error}
                            tabValue={tabValue}
                        />
                    </>
                ) : (
                    <Loading />
                )}
            </Box>
        </>
    );
};

export default YearlyContent;
