import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetAllUsersQuery, useSendInvitationMutation, useAddUserMutation } from '../../services/api';
import { useMediaQuery } from 'react-responsive';
import UsersHeader from './UsersHeader';
import UserList from './UserList';
import AddUserDialogBox from './AddUserDialogBox';
import ErrorSnackbar from '../../components/ErrorSnackbar';

const Users = () => {
  const { role, company } = useSelector((state) => state.global);
  const { data: usersData, refetch: refetchUsers } = useGetAllUsersQuery(company);
  const [sendInvitation] = useSendInvitationMutation();
  const [addUserMutation] = useAddUserMutation();
  const isSmallScreen = useMediaQuery({ maxWidth: 480 });

  const [openDialog, setOpenDialog] = useState(false);
  const [userData, setUserData] = useState({ name: '', email: '', country: '', phoneNumber: '', role: '' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleAddUser = async () => {
    try {
      if (!userData.name || !userData.email || !userData.role) {
        showSnackbar("Please fill all fields", 'error');
      } else {
        const addUserResponse = await addUserMutation({
          name: userData.name,
          email: userData.email,
          role: userData.role,
          company: company,
        });

        if (addUserResponse.error) {
          showSnackbar("User already exists", 'error');
        } else {
          await sendInvitation({
            name: userData.name,
            email: userData.email,
          });

          showSnackbar('User added successfully', 'success');
          refetchUsers();
        }
      }
    } catch (error) {
      console.error('Error adding user:', error);
      showSnackbar('Failed to add user', 'error');
    } finally {
      handleCloseDialog();
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  return (
    <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
      <UsersHeader
        role={role}
        handleOpenDialog={handleOpenDialog}
      />
      <UserList
        usersData={usersData}
        showSnackbar={showSnackbar}
        refetchUsers={refetchUsers}
        isSmallScreen={isSmallScreen}
      />
      <AddUserDialogBox
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
        userData={userData}
        handleInputChange={handleInputChange}
        handleAddUser={handleAddUser}
      />
      <ErrorSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={() => setSnackbar({ ...snackbar, open: false })} />
    </Box>
  );
};

export default Users;
