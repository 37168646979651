import React from 'react';
import { Box, useTheme } from '@mui/material';
import AddonManagementHeader from './AddonManagementHeader';
import { useMediaQuery } from 'react-responsive';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import AddonList from './AddonList';
import { useGetAddonsQuery } from "../../services/api";

const AddonManagement = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    const { companyOptions } = useCompanyOptions();
    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });

    const { data: addonData = [] } = useGetAddonsQuery(selectedCompany);

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <AddonManagementHeader
                isSmallScreen={isSmallScreen}
                selectedCompany={selectedCompany}
                handleCompanyChange={handleCompanyChange}
                companyOptions={companyOptions}
            />
            <AddonList
                addonData={addonData}
                theme={theme}
                isSmallScreen={isSmallScreen}
            />
        </Box>
    );
}

export default AddonManagement;
