import React from 'react';
import StatBox from '../../components/StatBox';
import { useMediaQuery } from 'react-responsive';

const StatBoxes = ({ totalDevicesConnected, totalSites, devicesOffline, totalHumanCount }) => {

    const isSmallScreen = useMediaQuery({ maxWidth: 480 })

    return (
        <>
            <StatBox
                title="DEVICES CONNECTED"
                value={totalDevicesConnected.toString()}
                isSmallScreen={isSmallScreen}
            />
            <StatBox
                title="ACTIVE SITES"
                value={totalSites.toString()}
                isSmallScreen={isSmallScreen}
            />
            <StatBox
                title="DEVICES OFFLINE"
                value={devicesOffline.toString()}
                isSmallScreen={isSmallScreen}
            />
            <StatBox
                title="TOTAL HUMAN COUNT"
                value={totalHumanCount.toString()}
                isSmallScreen={isSmallScreen}
            />
        </>
    )
}

export default StatBoxes