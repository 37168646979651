import React from 'react';
import { Box, useTheme } from '@mui/material';
import Header from '../../components/Header';

const DowntimeLogHeader = ({ isSmallScreen, children }) => {

    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            justifyContent="space-between"
            marginBottom= "1rem"
            padding="0.5rem"
            backgroundColor={theme.palette.background.paper}
            borderRadius='15px'
        >
            <Header title="DEVICE DOWNTIME LOG" subtitle="View precise downtime of your devices" />
            <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} alignItems="center" gap={1} mr={1}>
                {children}
            </Box>
        </Box>
    );
};

export default DowntimeLogHeader;