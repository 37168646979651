import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from "react-redux";
import { Box, useTheme } from '@mui/material';
import DevicesHeader from './DevicesHeader';
import DeviceList from './DeviceList';
import AddDeviceDialogBox from './AddDeviceDialogBox';
import ErrorSnackbar from '../../components/ErrorSnackbar';
import { useGetDevicesQuery, useAddDeviceMutation, useGetSitesQuery, useAddDeviceToSiteMutation } from "../../services/api";
import { useMediaQuery } from 'react-responsive';

const Devices = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });
    const { company, role } = useSelector((state) => state.global);
    const isAdmin = role === "Admin";
    const [openDialog, setOpenDialog] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
    const [addDevice, { isError: addDeviceError }] = useAddDeviceMutation();
    const [addDeviceToSite, { isError: addDeviceToSiteError }] = useAddDeviceToSiteMutation();
    const { data: devicesData, refetch } = useGetDevicesQuery(company);
    const { data: sitesData } = useGetSitesQuery(company);

    useEffect(() => {
        if (addDeviceError || addDeviceToSiteError) {
        window.location.href = '/login';
        }
    }, [addDeviceError, addDeviceToSiteError]);

    useEffect(() => {
        refetch();
        const interval = setInterval(() => refetch(), 60000);
        return () => clearInterval(interval);
    }, [refetch]);

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

    const filteredSites = useMemo(() => {
        return sitesData ? sitesData.filter(site => site.company === company && site.enabled) : [];
    }, [sitesData, company]);

    const filteredDevices = useMemo(() => {
        return devicesData && devicesData.filter(device => device.addedToDashboard && device.company === company);
    }, [devicesData, company]);

    const handleAddDevice = async (ipAddress, macAddress, site) => {
        if (!ipAddress || !macAddress || !site) {
            setSnackbar({
                open: true,
                message: "Please fill all fields and select a site.",
                severity: "error"
            });
            return;
        }

        try {
            const response = await addDevice({ ipAddress, macAddress, site });
            if (response.error) {
                setSnackbar({
                    open: true,
                    message: "ERROR: No matching device for your company.",
                    severity: "error"
                });
            } else {
                await addDeviceToSite({ company, name: site, macAddress });
                setSnackbar({
                    open: true,
                    message: "Device added successfully!",
                    severity: "success"
                });
                handleCloseDialog();
                refetch();
            }
        } catch (error) {
            console.error('Error adding device:', error);
        }
    };

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
        <DevicesHeader
            isAdmin={isAdmin}
            handleOpenDialog={handleOpenDialog}
            theme={theme}
        />
        <DeviceList
            filteredDevices={filteredDevices}
            theme={theme}
            isSmallScreen={isSmallScreen}
        />
        <AddDeviceDialogBox
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            handleAddDevice={handleAddDevice}
            filteredSites={filteredSites}
            theme={theme}
        />
        <ErrorSnackbar
            open={snackbar.open}
            onClose={handleSnackbarClose}
            message={snackbar.message}
            severity={snackbar.severity}
        />
        </Box>
    );
};

export default Devices;
