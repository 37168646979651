import React from 'react';
import { useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import { gradientDefs } from '../components/gradientDefs';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import CustomTooltipPie from '../components/customTooltipPie';

const DashboardTotalPie = ({ data, isLoading }) => {
    const themeMode = useSelector(({ global }) => global.mode);

    if (isLoading) {
        return (
            <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    if (!data) {
        return <NoDataAvailableMessage />
    }

    const pieData = Object.entries(data).map(([key, value]) => ({
        id: key,
        value,
    }));

    const fill = pieData.map((_, index) => ({
        match: { id: pieData[index].id },
        id: `gradient${index + 1}`,
    }));

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    return (
        <div style={{ position: 'relative', height: 300 }}>
            <ResponsivePie
                data={pieData}
                margin={{ top: 20, left: 80, right: 80, bottom: 20 }}
                startAngle={-150}
                innerRadius={0.5}
                activeOuterRadiusOffset={8}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsDiagonalLength={9}
                arcLinkLabelsTextColor={textColor}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={textColor}
                arcLinkLabel="value"
                enableArcLabels={false}
                arcLabelsTextColor={{ from: textColor, modifiers: [['darker', 3]] }}
                defs={gradientDefs}
                fill={fill}
                tooltip={({ datum }) => <CustomTooltipPie datum={datum} />}
                legends={[]}
            />
        </div>
    );
};

export default DashboardTotalPie;
