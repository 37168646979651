import React, { useState, useMemo, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import { useGetUserQuery } from "../../services/api";

const Layout = () => {
    // State variables
    const isNonMobile = useMediaQuery("(min-width: 1440px)");
    const [isSidebarOpen, setIsSidebarOpen] = useState(isNonMobile);

    useEffect(() => {
        setIsSidebarOpen(isNonMobile);
    }, [isNonMobile]);

    // Redux state
    const userId = useSelector((state) => state.global.userId);
    
    // API data
    const { data } = useGetUserQuery(userId);
    
    // Memoized user data
    const userData = useMemo(() => data || {}, [data]);

    const handleSidebarClose = () => {
        if (!isNonMobile) {
            setIsSidebarOpen(false);
        }
    };

    return (
        <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
            <Sidebar
                user={userData}
                isNonMobile={isNonMobile}
                drawerWidth="250px"
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                handleSidebarClose={handleSidebarClose}
            />
            <Box flexGrow={1}>
                <Navbar
                    user={userData}
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                />
                <Outlet />
            </Box>
        </Box>
    );
};

export default Layout;
