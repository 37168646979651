import React from 'react';
import { Box, Button, Popover, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const MonthPopover = ({ anchorEl, handlePopoverOpen, handlePopoverClose, handleDateChange, handleApply, isSmallScreen, tempSelectedMonth, tempSelectedYear }) => {
    const theme = useTheme();

    // Combine tempSelectedMonth and tempSelectedYear into a dayjs object
    const selectedDate = tempSelectedMonth && tempSelectedYear
        ? dayjs(`${tempSelectedYear}-${tempSelectedMonth}-01`, 'YYYY-M-D')
        : null;

    return (
        <>
            <Button
                variant="outlined"
                size="large"
                style={{
                    color: theme.palette.text.white,
                    borderColor: '#bbbdbd',
                    width: '100%',
                    marginBottom: isSmallScreen ? '0.5rem' : '0'
                }}
                onClick={handlePopoverOpen}
            >
                {selectedDate ? selectedDate.format('MMM YYYY') : 'Select Month'}
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{ sx: { marginTop: '20px' } }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        views={['year', 'month']}
                        onChange={handleDateChange}
                        openTo="month"
                        date={selectedDate || dayjs()}
                    />
                </LocalizationProvider>
                <Box mt={-6} mb={2} display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" onClick={handleApply}>
                        Apply
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default MonthPopover;
