import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useGetTotalCompaniesQuery } from '../../services/api';
import HomepageHeader from './HomepageHeader';
import AdminStatBoxes from './AdminStatBoxs';
import MapComponent from './MapComponent';
import GrowthChart from './GrowthChart';

const Homepage = () => {

    // Custom hooks
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

    // Fetch total counts from API
    const { data: counts } = useGetTotalCompaniesQuery();

    // Extract yearsOfCompanyCreation from counts
    const yearsOfCompanyCreation = counts?.yearsOfCompanyCreation;

    // JSX
    return (
        <Box m="1rem 2.5rem">
            <HomepageHeader />
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" }
                }}
            >
                <AdminStatBoxes counts={counts} />
                <GrowthChart yearsOfCompanyCreation={yearsOfCompanyCreation} />
                <MapComponent />
            </Box>
        </Box>
    );
}

export default Homepage;
