import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode: "light",
    isLoggedIn: false,
    id: null,
    username: null,
    company: null,
    role: null,
    permissions: [],
    selectedDevice: null,
    selectedCompany: null,
    assignedCompanies: []
};

export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === "light" ? "dark" : "light";
        },
        login: (state) => {
            state.isLoggedIn = true;
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.permissions = [];
        },
        setId: (state, action) => {
            state.id = action.payload;
        },
        setUsername: (state, action) => {
            state.username = action.payload;
        },
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setPermissions: (state, action) => {
            state.permissions = action.payload;
        },
        setSelectedDevice: (state, action) => {
            state.selectedDevice = action.payload;
        },
        setSelectedCompany: (state, action) => {
            state.selectedCompany = action.payload;
        },
        setAssignedCompanies: (state, action) => {
            state.assignedCompanies = action.payload;
        },
    },
});

export const { setMode, login, logout, setId, setUsername, setCompany, setRole, setPermissions, setSelectedDevice, setSelectedCompany, setAssignedCompanies } = globalSlice.actions;

export default globalSlice.reducer;
