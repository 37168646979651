import React, { useState } from 'react';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UpdateCompanyDialogBox from './UpdateCompanyDialogBox';

const CompanyDetails = ({ companyData, selectedCompany, refetch }) => {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);

    // Find the selected company based on the name
    const selectedCompanyData = companyData.find(company => company.name === selectedCompany);

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    return (
        <Box
            gridColumn="span 6"
            gridRow="span 2"
            backgroundColor={theme.palette.background.paper}
            borderRadius="0.55rem"
            position="relative"
            overflow="auto"
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" sx={{ color: theme.palette.secondary.main, m: "4%", fontWeight: 'bold' }}>
                    COMPANY DETAILS
                </Typography>
                <IconButton
                    sx={{
                        marginRight: '4%',
                        '&:focus': {
                            outline: 'none',
                        },
                    }}
                    onClick={handleOpenDialog}
                >
                    <EditIcon sx={{ color: theme.palette.secondary.main }} />
                </IconButton>
            </Box>
            
            {selectedCompanyData && (
                <Box sx={{ ml: '4%' }}>
                    <Typography variant="h4" gutterBottom>
                        <strong>Address:</strong> {selectedCompanyData.address}
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <strong>City:</strong> {selectedCompanyData.city}
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <strong>Country:</strong> {selectedCompanyData.country}
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <strong>Industry:</strong> {selectedCompanyData.industry}
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <strong>Contact Email:</strong> {selectedCompanyData.contactEmail}
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <strong>Contact Phone:</strong> {selectedCompanyData.contactPhone}
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                        <strong>Website:</strong> <a href={selectedCompanyData.website} target="_blank" rel="noopener noreferrer">{selectedCompanyData.website}</a>
                    </Typography>
                </Box>
            )}

            <UpdateCompanyDialogBox
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                companyData={selectedCompanyData}
                refetch={refetch}
            />
        </Box>
    );
}

export default CompanyDetails;
