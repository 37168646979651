import { useMemo } from 'react';
import { useGetDeviceNamesQuery } from '../services/api';

const useSensorOptions = (selectedCompany) => {
    const { data: devicesData } = useGetDeviceNamesQuery(selectedCompany);

    const sensorOptions = useMemo(() => {
        if (devicesData && selectedCompany) {
            const filteredDevices = devicesData.filter(device => device.addedToDashboard);
            return filteredDevices.map(device => ({
                mac: device.mac,
                sensorName: device.name
            }));
        }
        return [];
    }, [devicesData, selectedCompany]);

    return sensorOptions;
};

export default useSensorOptions;
