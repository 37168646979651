import React from 'react';
import { Box, Typography, useTheme, Button } from '@mui/material';
import Header from '../../components/Header';
import CompanySelector from '../../components/CompanySelector';
import { AddBoxOutlined } from '@mui/icons-material';

const AllSitesHeader = ({ selectedCompany, handleCompanyChange, companyOptions, handleOpenDialog, isSmallScreen }) => {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" gap="1rem">
            {isSmallScreen ? (
                <>
                    <Box 
                        display="flex" 
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="0.5rem"
                    >
                        <Header title="ALL SITES" subtitle="View all registered site details for companies" />
                    </Box>
                    <Box 
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="1rem"
                        gap="1rem"
                    >
                        <CompanySelector 
                            selectedCompany={selectedCompany}
                            handleCompanyChange={handleCompanyChange}
                            companyOptions={companyOptions}
                        />
                        <Button
                            onClick={handleOpenDialog}
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.text.black,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "13px 20px",
                                width: "auto",
                                margin: "0 auto",
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        >
                            <AddBoxOutlined sx={{ marginRight: "8px" }} />
                            Add New Site
                        </Button>
                    </Box>
                </>
            ) : (
                <Box 
                    display="flex" 
                    justifyContent="space-between" 
                    width="100%" 
                    backgroundColor={theme.palette.background.paper} 
                    borderRadius='15px' 
                    padding="0.5rem"
                >
                    <Header title="ALL SITES" subtitle="View all registered site details for companies" />
                    <Box display="flex" alignItems="center" gap="1rem">
                        <CompanySelector 
                            selectedCompany={selectedCompany}
                            handleCompanyChange={handleCompanyChange}
                            companyOptions={companyOptions}
                        />
                        <Button
                            onClick={handleOpenDialog}
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.text.black,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "13px 20px",
                                minWidth: "150px",
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        >
                            <AddBoxOutlined sx={{ marginRight: "8px" }} />
                            Add New Site
                        </Button>
                    </Box>
                </Box>
            )}
            {selectedCompany && (
                <Box
                    backgroundColor={theme.palette.background.paper}
                    borderRadius='15px'
                    padding="0.5rem"
                >
                    <Typography
                        variant={isSmallScreen ? "h3" : "h2"}
                        component="div"
                        sx={{
                            color: theme.palette.secondary.main,
                            fontWeight: "bold",
                            textAlign: "left",
                            ml: isSmallScreen ? '0.5rem' : '0',
                        }}
                    >
                        {selectedCompany}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default AllSitesHeader;
