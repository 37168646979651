import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useAddSiteMutation, useGetSitesQuery, useDisableSiteMutation, useEnableSiteMutation, useRemoveSiteMutation } from '../../services/api';
import ErrorSnackBar from './ErrorSnackBar';
import mainLocationsInQatar from '../../constants/mainLocations';
import Loading from '../../components/Loading';
import { useMediaQuery } from 'react-responsive';
import SitesHeader from './SitesHeader';
import SitesContent from './SiteContent';
import AddSiteDialogBox from './AddSiteDialogBox';

const Sites = () => {
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });
    const { role, company } = useSelector((state) => state.global);
    const { data: sitesData, refetch, isError: siteDataError } = useGetSitesQuery(company);
    const [addSite, { isError: addSiteError }] = useAddSiteMutation();
    const isAdmin = role === 'Admin';
    const [openDialog, setOpenDialog] = useState(false);
    const [siteData, setSiteData] = useState({ name: '', location: '', latitude: '', longitude: '' });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
    const [disableSiteMutation, { isError: disableSiteError }] = useDisableSiteMutation();
    const [enableSiteMutation, { isError: enableSiteError }] = useEnableSiteMutation();
    const [removeSiteMutation, { isError: removeSiteError }] = useRemoveSiteMutation();

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (addSiteError || disableSiteError || enableSiteError || removeSiteError) {
            window.location.href = '/login';
        }
    }, [addSiteError, disableSiteError, enableSiteError, removeSiteError]);

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSiteData({
            ...siteData,
            [name]: value,
        });
    };

    const handleAddSite = async () => {
        try {
            const { name, location, latitude, longitude } = siteData;
            if (name && location && latitude && longitude) {
                await addSite({ ...siteData, company });
                handleCloseDialog();
                refetch();
                setSiteData({ name: '', location: '', latitude: '', longitude: '' });
                setSnackbar({ open: true, message: "Site added successfully", severity: "success" });
            } else {
                setSnackbar({ open: true, message: "Please fill in all the fields", severity: "error" });
            }
        } catch (error) {
            console.error('Error adding site:', error);
        }
    };

    const handleSiteStatusChange = async (siteId, newStatus) => {
        try {
        if (newStatus === 'disabled') {
            await disableSiteMutation(siteId);
            setSnackbar({ open: true, message: `Site disabled successfully`, severity: "success" });
        } else if (newStatus === 'enabled') {
            await enableSiteMutation(siteId);
            setSnackbar({ open: true, message: `Site enabled successfully`, severity: "success" });
        }
            refetch();
        } catch (error) {
            console.error('Error updating site status:', error);
            setSnackbar({ open: true, message: 'Failed to update site status. Please try again later.', severity: "error" });
        }
    };

    const handleRemoveSite = async (siteId) => {
        try {
            await removeSiteMutation(siteId);
            refetch();
            setSnackbar({ open: true, message: "Site removed successfully", severity: "success" });
        } catch (error) {
            console.error('Error removing site:', error);
        }
    };

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <SitesHeader isAdmin={isAdmin} handleOpenDialog={handleOpenDialog} isSmallScreen={isSmallScreen} />
            {siteDataError ? (
                <Loading />
            ) : (
                <SitesContent
                sitesData={sitesData}
                siteDataError={siteDataError}
                isSmallScreen={isSmallScreen}
                handleSiteStatusChange={handleSiteStatusChange}
                handleRemoveSite={handleRemoveSite}
                />
            )}
            <AddSiteDialogBox
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                handleInputChange={handleInputChange}
                handleAddSite={handleAddSite}
                siteData={siteData}
                mainLocationsInQatar={mainLocationsInQatar}
            />
            <ErrorSnackBar
                open={snackbar.open}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Box>
    );
};

export default Sites;
