import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { customColorScheme } from '../components/gradientDefs';

const OverviewChart = ({ isSmallScreen, selectedMac, error, overviewData, overviewLoading, tabValue }) => {
    const [activeLegends, setActiveLegends] = useState([]);
    const themeMode = useSelector(({ global }) => global.mode);
    const theme = useTheme();

    if (overviewLoading) {
        return <Loading />;
    }

    if (error || error?.status || !overviewData || Object.keys(overviewData).length === 0) {
        return <NoDataAvailableMessage />;
    }

    const processData = (data) => {
        if (!data || !data[selectedMac]) return [];
        const filteredData = data[selectedMac];
        const months = Object.entries(filteredData).flatMap(([year, yearData]) =>
            Object.entries(yearData).map(([month, counts]) => {
                const date = new Date(`${year}-${month}-01`);
                const formattedMonth = date.toLocaleString('default', { month: 'short' }).toUpperCase();
                const shortYear = year.slice(-2);
                return {
                    month: `${formattedMonth}-${shortYear}`,
                    ...counts,
                };
            })
        );
        return months;
    };

    const processedData = processData(overviewData);
    
    if (error || !processedData.length) {
        return <NoDataAvailableMessage />;
    }

    const lineIds = new Set();
    processedData.forEach((entry) => {
        Object.keys(entry).forEach((key) => {
            if (key !== 'month') {
                lineIds.add(key);
            }
        });
    });

    const lineData = Array.from(lineIds).map((id) => ({
        id,
        data: processedData.map((entry) => ({ x: entry.month, y: entry[id] || 0 })),
    }));

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.id)
                ? prevActiveLegends.filter((item) => item !== legendItem.id)
                : [...prevActiveLegends, legendItem.id]
        );
    };

    // Determine which lines to show based on activeLegends
    const filteredLineData = lineData.map(line => ({
        ...line,
        data: line.data.map(point => ({
            ...point,
            y: activeLegends.length === 0 || !activeLegends.includes(line.id) ? point.y : null
        }))
    }));

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    return (
        <div style={{
            height: '70vh',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '15px',
            marginTop: '1rem'
        }}>
            <ResponsiveLine
                data={filteredLineData}
                margin={isSmallScreen ? { top: 30, right: 20, bottom: 120, left: 50 } : { top: 30, right: 30, bottom: 120, left:50 }}
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: false,
                    reverse: false
                }}
                yFormat=" >-.2f"
                curve="catmullRom"
                axisTop={null}
                axisRight={null}
                lineWidth={3}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -40,
                    legendOffset: 60,
                    legendPosition: 'middle',
                    truncateTickAt: 0,
                    legend: 'Month'
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -40,
                    legendPosition: 'middle',
                    truncateTickAt: 4
                }}
                enableGridX={false}
                enablePoints={false}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabel="data.yFormatted"
                pointLabelYOffset={-12}
                enableTouchCrosshair={true}
                useMesh={true}
                colors={customColorScheme}
                theme={{
                    textColor: textColor,
                    axis: {
                        legend: { text: { fill: textColor } },
                        ticks: { text: { fill: textColor } },
                    },
                }}
                tooltip={({ point }) => (
                    <div style={{ background: '#000', color: '#fff', padding: '10px', fontWeight: 'bold' }}>
                        {point.serieId} : {point.data.y}
                    </div>
                )}
                legends={
                    isSmallScreen ? [
                        {
                            anchor: 'bottom',
                            direction: 'row',
                            justify: false,
                            translateX: tabValue === 1 ? -15 : -15,
                            translateY: 110,
                            itemsSpacing: tabValue === 1 ? 15 : 15,
                            itemDirection: 'left-to-right',
                            itemWidth: tabValue === 1 ? 80 : 68,
                            itemHeight: 26,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: { itemBackground: 'rgba(0, 0, 0, .03)', itemOpacity: 1 },
                                },
                            ],
                            textColor: textColor,
                            itemTextColor: textColor,
                            onClick: handleLegendClick
                        },
                    ] : [
                        {
                            anchor: 'bottom',
                            direction: 'row',
                            justify: false,
                            translateX: -10,
                            translateY: 100,
                            itemsSpacing: 40,
                            itemDirection: 'left-to-right',
                            itemWidth: 70,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ],
                            onClick: handleLegendClick
                        },
                    ]
                }
            />
        </div>
    );
};

export default OverviewChart;
