import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, Typography } from '@mui/material';

const LicenseTable = ({ sortedLicenseData, orderBy, order, createSortHandler }) => {

    const getPackageStatus = (license) => {
        if (license.package?.durationInDays === -1) {
            return 'Active';
        }
        const currentDate = new Date();
        const endDate = new Date(license.endDate);
        return endDate >= currentDate ? 'Active' : 'Expired';
    };

    const calculateRemainingDays = (license) => {
        if (license.package?.durationInDays === -1) {
            return '-';
        }
        if (!license.endDate) return 'N/A';
        const currentDate = new Date();
        const end = new Date(license.endDate);
        const timeDiff = end - currentDate;
        return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'device.name'}
                            direction={orderBy === 'device.name' ? order : 'asc'}
                            onClick={createSortHandler('device.name')}
                        >
                            Device
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'package.name'}
                            direction={orderBy === 'package.name' ? order : 'asc'}
                            onClick={createSortHandler('package.name')}
                        >
                            Package
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'status'}
                            direction={orderBy === 'status' ? order : 'asc'}
                            onClick={createSortHandler('status')}
                        >
                            Status
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'startDate'}
                            direction={orderBy === 'startDate' ? order : 'asc'}
                            onClick={createSortHandler('startDate')}
                        >
                            Start
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'endDate'}
                            direction={orderBy === 'endDate' ? order : 'asc'}
                            onClick={createSortHandler('endDate')}
                        >
                            End
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'remainingDays'}
                            direction={orderBy === 'remainingDays' ? order : 'asc'}
                            onClick={createSortHandler('remainingDays')}
                        >
                            Remaining Days
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sortedLicenseData.map((license) => {
                    const status = getPackageStatus(license);
                    const statusColor = status === 'Active' ? 'green' : 'red';
                    const remainingDays = calculateRemainingDays(license);
                    const endDateDisplay = license.package?.durationInDays === -1 ? 'Lifetime' : new Date(license.endDate).toLocaleDateString();

                    return (
                        <TableRow key={license._id}>
                            <TableCell>{license.device?.name || 'N/A'}</TableCell>
                            <TableCell>{license.package?.name || 'N/A'}</TableCell>
                            <TableCell>
                                <Typography style={{ color: statusColor, fontWeight: 'bold' }}>
                                    {status}
                                </Typography>
                            </TableCell>
                            <TableCell>{license.startDate ? new Date(license.startDate).toLocaleDateString() : 'N/A'}</TableCell>
                            <TableCell>{endDateDisplay}</TableCell>
                            <TableCell>{remainingDays}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default LicenseTable;
