import React, { useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CompanyDetails from './CompanyDetails';
import CompanyUsersTable from './CompanyUsersTable';
import ConnectedDevicesAndSitesTable from './ConnectedDevicesAndSitesTable';
import LicenseTable from './LicenseTable';

const CompanyContent = ({ companyData, refetch, selectedCompany, userData, devicesData, licenseData, isSmallScreen }) => {
    const theme = useTheme();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const filteredDevicesData = useMemo(() => {
        if (!selectedCompany || !devicesData) {
            return devicesData;
        } else {
            return devicesData.filter(device => device.company === selectedCompany);
        }
    }, [devicesData, selectedCompany]);

    const sortedUserData = useMemo(() => {
        if (userData) {
            const sortedUsers = [...userData.users].sort((a, b) => {
                if (order === 'asc') {
                    return a[orderBy] > b[orderBy] ? 1 : -1;
                } else {
                    return b[orderBy] > a[orderBy] ? 1 : -1;
                }
            });
            return sortedUsers;
        }
        return [];
    }, [userData, orderBy, order]);

    const sortedDeviceData = useMemo(() => {
        if (filteredDevicesData) {
            const sortedDevices = [...filteredDevicesData].sort((a, b) => {
                if (order === 'asc') {
                    return a[orderBy] > b[orderBy] ? 1 : -1;
                } else {
                    return b[orderBy] > a[orderBy] ? 1 : -1;
                }
            });
            return sortedDevices;
        }
        return [];
    }, [filteredDevicesData, orderBy, order]);

    const sortedLicenseData = useMemo(() => {
        if (!licenseData) return [];
        
        const processedData = licenseData.map(license => {
            const status = new Date(license.endDate) > new Date() ? 'Active' : 'Inactive';
            const remainingDays = Math.max(0, Math.floor((new Date(license.endDate) - new Date()) / (1000 * 60 * 60 * 24)));

            return {
                ...license,
                status,
                remainingDays,
            };
        });

        return processedData.sort((a, b) => {
            if (order === 'asc') {
                return a[orderBy] > b[orderBy] ? 1 : -1;
            } else {
                return b[orderBy] > a[orderBy] ? 1 : -1;
            }
        });
    }, [licenseData, orderBy, order]);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(property);
    };

    return (
        <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="160px"
            gap="20px"
            pb="20px"
        >
            <Box
                gridColumn={isSmallScreen ? "span 12" : "span 6"}
                gridRow="span 2"
                backgroundColor={theme.palette.background.paper}
                borderRadius="0.55rem"
                position="relative"
                overflow="auto"
            >
                <CompanyDetails
                    companyData={companyData}
                    selectedCompany={selectedCompany}
                    refetch={refetch}
                />
            </Box>

            <Box
                gridColumn={isSmallScreen ? "span 12" : "span 6"}
                gridRow="span 2"
                backgroundColor={theme.palette.background.paper}
                borderRadius="0.55rem"
                position="relative"
                p="4%"
                overflow="auto"
                sx={{
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '-ms-overflow-style': 'none',
                    scrollbarWidth: 'none'
                }}
            >
                <Typography
                    variant="h3"
                    sx={{ color: theme.palette.secondary.main, mb: "2%", fontWeight: 'bold' }}
                >
                    COMPANY USERS
                </Typography>
                <CompanyUsersTable
                    sortedUserData={sortedUserData}
                    orderBy={orderBy}
                    order={order}
                    createSortHandler={createSortHandler}
                />
            </Box>

            <Box
                gridColumn={isSmallScreen ? "span 12" : "span 6"}
                gridRow="span 2"
                backgroundColor={theme.palette.background.paper}
                borderRadius="0.55rem"
                position="relative"
                p="4%"
                overflow="auto"
                sx={{
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '-ms-overflow-style': 'none',
                    scrollbarWidth: 'none'
                }}
            >
                <Typography
                    variant="h3"
                    sx={{ color: theme.palette.secondary.main, mb: "2%", fontWeight: 'bold' }}
                >
                    CONNECTED DEVICES & SITES
                </Typography>
                <ConnectedDevicesAndSitesTable
                    sortedDeviceData={sortedDeviceData}
                    orderBy={orderBy}
                    order={order}
                    createSortHandler={createSortHandler}
                />
            </Box>
            <Box
                gridColumn={isSmallScreen ? "span 12" : "span 6"}
                gridRow="span 2"
                backgroundColor={theme.palette.background.paper}
                borderRadius="0.55rem"
                position="relative"
                p="4%"
                overflow="auto"
                sx={{
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '-ms-overflow-style': 'none',
                    scrollbarWidth: 'none'
                }}
            >
                <Typography
                    variant="h3"
                    sx={{ color: theme.palette.secondary.main, mb: "2%", fontWeight: 'bold' }}
                >
                    LICENSES
                </Typography>
                <LicenseTable
                    sortedLicenseData={sortedLicenseData}
                    orderBy={orderBy}
                    order={order}
                    createSortHandler={createSortHandler}
                />
            </Box>
        </Box>
    );
};

export default CompanyContent;
