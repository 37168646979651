import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useGetAllUsersQuery, useGetDevicesQuery, useGetLicensesQuery } from "../../services/api";
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import CompanyStats from './CompanyStats';
import CompanyHeader from './CompanyHeader';
import CompanyContent from './CompanyContent';
import AddCompanyDialogBox from './AddCompanyDialogBox';
import { useMediaQuery } from 'react-responsive';

const Companies = () => {
    const { companyOptions, refetchCompanyOptions } = useCompanyOptions();
    const { selectedCompany, handleCompanyChange, getSelectedCompanyLabel } = useSelectedCompany({ companyOptions });
    const { data: userData } = useGetAllUsersQuery(selectedCompany);
    const { data: devicesData, refetch } = useGetDevicesQuery(selectedCompany);
    const { data: licenseData } = useGetLicensesQuery(selectedCompany); 
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <CompanyHeader
                    companyOptions={companyOptions}
                    selectedCompany={selectedCompany}
                    handleCompanyChange={handleCompanyChange}
                    handleOpenDialog={handleOpenDialog}
                    getSelectedCompanyLabel={getSelectedCompanyLabel()}
                    isSmallScreen={isSmallScreen}
                />
            </Box>
            <CompanyStats companyOptions={companyOptions} selectedCompany={selectedCompany} />
            <CompanyContent
                companyData={companyOptions}
                refetch={refetchCompanyOptions}
                selectedCompany={selectedCompany}
                userData={userData}
                devicesData={devicesData}
                licenseData={licenseData}
                isSmallScreen={isSmallScreen}
            />
            <AddCompanyDialogBox
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                refetch={refetch}
            />
        </Box>
    );
};

export default Companies;
