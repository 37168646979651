import React from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Button, TextField, FormControl, InputLabel, Select, MenuItem, Slide
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUserDialogBox = ({ open, handleCloseDialog, userData, handleInputChange, handleAddUser }) => {
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleCloseDialog}
            aria-describedby="add-user-dialog-description"
            PaperProps={{
                sx: { 
                backgroundColor: theme.palette.background.paper, 
                maxWidth: '500px'
                }
            }}
        >
        <DialogTitle variant='h2' fontWeight='bold' sx={{ color: theme.palette.secondary.main }}>
            Add New User
        </DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                fullWidth
                value={userData.name}
                onChange={handleInputChange}
            />
            <TextField
                margin="dense"
                id="email"
                name="email"
                label="Email"
                fullWidth
                value={userData.email}
                onChange={handleInputChange}
            />
            <FormControl fullWidth margin="dense">
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                    labelId="role-label"
                    id="role"
                    name="role"
                    label="Role"
                    value={userData.role}
                    onChange={handleInputChange}
                    sx={{ color: theme.palette.secondary[200] }}
                >
                    <MenuItem value="Power Admin">Power Admin</MenuItem>
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: '15px', paddingTop: '0px' }}>
            <Button
                onClick={handleCloseDialog}
                variant="contained"
                sx={{
                    color: theme.palette.text.black,
                    backgroundColor: theme.palette.primary.main,
                    fontWeight: 'bold',
                    '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    },
                    '&:focus': {
                    outline: 'none',
                    },
                }}
            >
                Cancel
            </Button>
            <Button
                onClick={handleAddUser}
                variant="contained"
                sx={{
                    color: theme.palette.text.black,
                    backgroundColor: theme.palette.primary.main,
                    fontWeight: 'bold',
                    '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    },
                    '&:focus': {
                    outline: 'none',
                    },
                }}
            >
                Add
            </Button>
        </DialogActions>
        </Dialog>
    );
};

export default AddUserDialogBox;
