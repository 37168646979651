import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import DashboardYearlyChart from '../../charts/dashboardCharts/DashboardYearlyChart';
import { useMediaQuery } from 'react-responsive';

const YearlyChart = ({ data, isLoading }) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    return (
        <Box
            gridColumn={isSmallScreen ? "span 12" : "span 8"}
            gridRow="span 2"
            backgroundColor={theme.palette.background.paper}
            borderRadius="0.55rem"
            postion="relative"
        >
            <Typography variant='h3' sx={{ color: theme.palette.secondary.main, mt: "2%", ml: "2%", fontWeight: "bold"}}>
                YEARLY COUNT
            </Typography>
            <Box style={{ height: "100%", width : "100%"}} postion="absolute">
                <DashboardYearlyChart processedData={data} isLoading={isLoading} />
            </Box>
        </Box>
    )
}

export default YearlyChart