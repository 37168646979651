import React, { useMemo, useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, IconButton, Box, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditPermissionsDialogBox from './EditPermissionsDialogBox';
import { useAddPermissionToUserMutation, useRemovePermissionFromUserMutation, useAssignCompanyToPowerAdminMutation, useRemoveCompanyFromPowerAdminMutation } from '../../services/api';
import useCompanyOptions from '../../hooks/useCompanyOptions';

const AdminManagementTable = ({ userData, permissionsData, isSmallScreen, refetch, showSnackbar }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const theme = useTheme();

    const [addPermissionToRole] = useAddPermissionToUserMutation();
    const [removePermissionFromRole] = useRemovePermissionFromUserMutation();
    const [assignCompanyToPowerAdmin] = useAssignCompanyToPowerAdminMutation();
    const [removeCompanyFromPowerAdmin] = useRemoveCompanyFromPowerAdminMutation();

    const { companyOptions } = useCompanyOptions();

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(property);
    };

    const handleEditClick = (user) => {
        setSelectedUser(user);
        setSelectedPermissions(user.permissions.map(p => p._id) || []);
        setSelectedCompanies(user.assignedCompanies.map(c => c._id) || []);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedUser(null);
        setSelectedPermissions([]);
        setSelectedCompanies([]);
    };

    const handleSave = async () => {
        if (selectedUser) {
            try {
                const currentPermissions = selectedUser.permissions.map(p => p._id) || [];
                const uniqueSelectedPermissions = [...new Set(selectedPermissions)];
                
                const newPermissions = uniqueSelectedPermissions.filter(permissionId => !currentPermissions.includes(permissionId));
                const removedPermissions = currentPermissions.filter(permissionId => !uniqueSelectedPermissions.includes(permissionId));

                if (newPermissions.length > 0) {
                    for (const permissionId of newPermissions) {
                        await addPermissionToRole({ userId: selectedUser._id, permissionId });
                    }
                }

                if (removedPermissions.length > 0) {
                    for (const permissionId of removedPermissions) {
                        await removePermissionFromRole({ userId: selectedUser._id, permissionId });
                    }
                }

                const currentCompanies = selectedUser.assignedCompanies.map(c => c._id) || [];
                const uniqueSelectedCompanies = [...new Set(selectedCompanies)];
                
                const newCompanies = uniqueSelectedCompanies.filter(companyId => !currentCompanies.includes(companyId));
                const removedCompanies = currentCompanies.filter(companyId => !uniqueSelectedCompanies.includes(companyId));

                if (newCompanies.length > 0) {
                    for (const companyId of newCompanies) {
                        await assignCompanyToPowerAdmin({ userId: selectedUser._id, companyId });
                    }
                }

                if (removedCompanies.length > 0) {
                    for (const companyId of removedCompanies) {
                        await removeCompanyFromPowerAdmin({ userId: selectedUser._id, companyId });
                    }
                }

                handleClose();
                refetch();
            } catch (error) {
                console.error("Error updating permissions or companies:", error);
            }
        }
    };

    const handlePermissionChange = (event) => {
        const { value } = event.target;
        setSelectedPermissions(value);
    };

    const handleCompanyChange = (event) => {
        setSelectedCompanies(event.target.value);
    };

    const filteredUserData = useMemo(() => {
        if (userData?.users) {
            return userData.users.filter(user =>
                user.role?.name === 'Power Admin' || user.role?.name === 'Super Admin'
            );
        }
        return [];
    }, [userData]);

    const sortedData = useMemo(() => {
        if (filteredUserData.length > 0) {
            return filteredUserData.sort((a, b) => {
                if (order === 'asc') {
                    return a[orderBy] > b[orderBy] ? 1 : -1;
                } else {
                    return b[orderBy] > a[orderBy] ? 1 : -1;
                }
            });
        }
        return [];
    }, [filteredUserData, orderBy, order]);

    return (
        <Box
            backgroundColor={theme.palette.background.paper}
            borderRadius='15px'
            sx={{
                overflowX: isSmallScreen ? 'auto' : 'hidden',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                '-ms-overflow-style': 'none',
                scrollbarWidth: 'none'
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'name'}
                                direction={orderBy === 'name' ? order : 'asc'}
                                onClick={createSortHandler('name')}
                            >
                                Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'email'}
                                direction={orderBy === 'email' ? order : 'asc'}
                                onClick={createSortHandler('email')}
                            >
                                Email
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'roleName'}
                                direction={orderBy === 'roleName' ? order : 'asc'}
                                onClick={createSortHandler('roleName')}
                            >
                                Role
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Permissions</TableCell>
                        <TableCell>Assigned Companies</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'createdAt'}
                                direction={orderBy === 'createdAt' ? order : 'asc'}
                                onClick={createSortHandler('createdAt')}
                            >
                                Created Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'updatedAt'}
                                direction={orderBy === 'updatedAt' ? order : 'asc'}
                                onClick={createSortHandler('updatedAt')}
                            >
                                Updated Date
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedData.map((user) => (
                        <TableRow key={user._id}>
                            <TableCell>{user.name}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.role?.name || 'N/A'}</TableCell>
                            <TableCell>
                                {user.permissions.length > 0
                                    ? user.permissions.map(permission => permission.name).join(', ')
                                    : 'No Permissions'}
                            </TableCell>
                            <TableCell>
                                {user.assignedCompanies.length > 0
                                    ? user.assignedCompanies.map(company => company.name).join(', ')
                                    : 'No Companies Assigned'}
                            </TableCell>
                            <TableCell>{user.active ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{new Date(user.createdAt).toLocaleDateString()}</TableCell>
                            <TableCell>{new Date(user.updatedAt).toLocaleDateString()}</TableCell>
                            <TableCell align="right">
                                <IconButton onClick={() => handleEditClick(user)} sx={{ '&:focus': { outline: 'none' } }}>
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <EditPermissionsDialogBox
                open={open}
                onClose={handleClose}
                selectedUser={selectedUser}
                selectedPermissions={selectedPermissions}
                selectedCompanies={selectedCompanies}
                handlePermissionChange={handlePermissionChange}
                handleCompanyChange={handleCompanyChange}
                permissionsData={permissionsData}
                companyOptions={companyOptions}
                handleSave={handleSave}
                showSnackbar={showSnackbar}
            />
        </Box>
    );
};

export default AdminManagementTable;
