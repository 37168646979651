import React from 'react';
import { Box, Typography } from '@mui/material';
import SensorIframe from '../../components/SensorIframe';
import ShowLabelsCheckbox from '../../components/ShowLabelsCheckbox';
import SensorSelect from '../../components/SensorSelect';
import DatePopover from './DatePopover';
import CompanySelector from '../../components/CompanySelector';

const HourlyFilterBox = ({
    sensorLabel, isSmallScreen, showLabels, onShowLabelsChange, sensorOptions, selectedSensor, handleSensorChange, selectedDate, handleDateChange, handlePopoverOpen, handlePopoverClose, anchorEl, companyOptions, selectedCompany, handleCompanyChange, isSuperAdmin }) => {
    return (
        <Box
            backgroundColor={(theme) => theme.palette.background.paper}
            borderRadius="15px"
            p="0.5rem"
        >
            <SensorIframe sensorLabel={sensorLabel} isSmallScreen={isSmallScreen} />
            <Typography variant="h3" component="div" sx={{ color: (theme) => theme.palette.secondary.main, fontWeight: "bold", mt: 2, mb: 2, ml: 1 }}>
                FILTER
            </Typography>
            {isSuperAdmin && (
                <Box m={1}>
                    <Typography variant="h5" fontWeight="bold" sx={{ mb: 2, mt: 4 }}>CHANGE COMPANY</Typography>
                    <CompanySelector
                        selectedCompany={selectedCompany}
                        handleCompanyChange={handleCompanyChange}
                        companyOptions={companyOptions}
                    />
                </Box>
            )}
            <Box m={1}>
                <Typography variant="h5" fontWeight="bold" sx={{ mb: 2, mt: 4 }}>CHANGE DEVICE</Typography>
                <SensorSelect sensorOptions={sensorOptions} selectedSensor={selectedSensor} handleSensorChange={handleSensorChange} isSmallScreen={isSmallScreen} />
            </Box>
            <Box m={1}>
                <Typography variant="h5" fontWeight="bold" sx={{ mb: 2, mt: 4 }}>CHANGE DATE</Typography>
                <DatePopover
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                    handlePopoverOpen={handlePopoverOpen}
                    handlePopoverClose={handlePopoverClose}
                    anchorEl={anchorEl}
                    isSmallScreen={isSmallScreen}
                />
            </Box>
            <Box mb={2} mt={4} ml={1}>
                <ShowLabelsCheckbox
                    showLabels={showLabels}
                    onChange={onShowLabelsChange}
                    isSmallScreen={isSmallScreen}
                />
            </Box>
        </Box>
    );
};

export default HourlyFilterBox;
