import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import { format } from 'date-fns';
import { gradientDefs } from '../components/gradientDefs';
import Loading from '../../components/Loading';
import CustomTooltipPie from '../components/customTooltipPie';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useTheme } from '@mui/material';

const DailyPieChart = ({ isLoading, selectedMac, selectedDateRange, processedData, error, isSmallScreen, tabValue }) => {
    const themeMode = useSelector(({ global }) => global.mode);
    const theme = useTheme();
    const [activeLegends, setActiveLegends] = useState([]);

    if (isLoading) {
        return (
            <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    // Check for 404 error
    if (error && error.status === 404) {
        return <NoDataAvailableMessage />;
    }

    // Ensure processedData is valid and filter for selectedDateRange
    if (!processedData || !processedData[selectedMac]) {
        return <NoDataAvailableMessage />;
    }

    // Ensure selectedDateRange is valid and contains at least one date
    if (!selectedDateRange || selectedDateRange.length === 0) {
        return <NoDataAvailableMessage />;
    }
    const dateRange = selectedDateRange[0];
    const allDates = [];
    let currentDate = new Date(dateRange.startDate);
    while (currentDate <= dateRange.endDate) {
        allDates.push(format(currentDate, 'yyyy-MM-dd'));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    // Filter and aggregate data for the selected date range
    const filteredPieData = {};
    allDates.forEach(date => {
        const dateCounts = processedData[selectedMac][date];
        if (dateCounts) {
            Object.entries(dateCounts).forEach(([logic, count]) => {
                if (!filteredPieData[logic]) {
                    filteredPieData[logic] = 0;
                }
                filteredPieData[logic] += count;
            });
        }
    });
    const pieData = Object.entries(filteredPieData).map(([key, value]) => ({
        id: key,
        value,
    }));
    if (pieData.length === 0) {
        return <NoDataAvailableMessage />;
    }
    const fill = pieData.map((_, index) => ({
        match: { id: pieData[index].id },
        id: `gradient${index + 1}`,
    }));

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.id)
                ? prevActiveLegends.filter((item) => item !== legendItem.id)
                : [...prevActiveLegends, legendItem.id]
        );
    };

    const filteredPieDataToDisplay = pieData.filter((item) => !activeLegends.includes(item.id));

    return (
        <div style={{
            position: 'relative',
            height: isSmallScreen ? 400 : 600,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '15px',
            marginTop: '1rem',
        }}>
            <ResponsivePie
                data={filteredPieDataToDisplay}
                margin={isSmallScreen ? { top: -50, left: 65, right: 65, bottom: 25 } : { top: 75, left: 0, right: 0, bottom: 100 }}
                startAngle={-150}
                innerRadius={0.5}
                activeOuterRadiusOffset={8}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsDiagonalLength={9}
                arcLinkLabelsTextColor={textColor}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={textColor}
                arcLinkLabel="value"
                enableArcLabels={false}
                arcLabelsTextColor={{ from: textColor, modifiers: [['darker', 3]] }}
                defs={gradientDefs}
                fill={fill}
                tooltip={({ datum }) => <CustomTooltipPie datum={datum} />}
                legends={isSmallScreen ? [
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: tabValue === 1 ? 0 : 20,
                        translateY: -25,
                        itemsSpacing: tabValue === 1 ? 10 : 2,
                        itemWidth: tabValue === 1 ? 80 : 75,
                        itemHeight: 10,
                        itemTextColor: textColor,
                        itemDirection: 'left-to-right',
                        symbolSize: 12,
                        symbolShape: 'square',
                        itemOpacity: 0.85,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ],
                        data: pieData.map((d, index) => ({
                            id: d.id,
                            label: d.id,
                            color: `url(#gradient${index + 1})`,
                        })),
                        onClick: handleLegendClick,
                    }
                ] : [
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 85,
                        itemsSpacing: 50,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ],
                        data: pieData.map((d, index) => ({
                            id: d.id,
                            label: d.id,
                            color: `url(#gradient${index + 1})`,
                        })),
                        onClick: handleLegendClick,
                    }
                ]}
            />
        </div>
    );
};
export default DailyPieChart;