import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import DashboardTotalPie from '../../charts/dashboardCharts/DashboardTotalPie'
import { useMediaQuery } from 'react-responsive'

const TotalCountPie = ({ data, isLoading }) => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    return (
        <Box
            gridColumn={isSmallScreen ? "span 12" : "span 4"}
            gridRow="span 2"
            backgroundColor={theme.palette.background.paper}
            borderRadius="0.55rem"
        >
            <Typography variant="h3" sx={{ color: theme.palette.secondary.main, mt: 2, ml: 2, fontWeight: "bold"}}>
                TOTAL COUNT
            </Typography>
            <DashboardTotalPie data={data} isLoading={isLoading} />
        </Box>
    )
}

export default TotalCountPie