import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@mui/material';

const ConnectedDevicesAndSitesTable = ({ sortedDeviceData, orderBy, order, createSortHandler }) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'name'}
                            direction={orderBy === 'name' ? order : 'asc'}
                            onClick={createSortHandler('name')}
                        >
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'mac'}
                            direction={orderBy === 'mac' ? order : 'asc'}
                            onClick={createSortHandler('mac')}
                        >
                            MAC
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'ip'}
                            direction={orderBy === 'ip' ? order : 'asc'}
                            onClick={createSortHandler('ip')}
                        >
                            IP
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'status'}
                            direction={orderBy === 'status' ? order : 'asc'}
                            onClick={createSortHandler('status')}
                        >
                            Status
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'site'}
                            direction={orderBy === 'site' ? order : 'asc'}
                            onClick={createSortHandler('site')}
                        >
                            Site
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sortedDeviceData.map(device => (
                    <TableRow key={device._id}>
                        <TableCell>{device.name}</TableCell>
                        <TableCell>{device.mac}</TableCell>
                        <TableCell>{device.ip}</TableCell>
                        <TableCell>
                            <span style={{ color: device.status === 'ONLINE' ? 'green' : 'red' }}>
                                {device.status}
                            </span>
                        </TableCell>
                        <TableCell>{device.site}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default ConnectedDevicesAndSitesTable;
