import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRightOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import FlexBetween from './FlexBetween';
import logoDark from "../assets/logo-dark.png";
import logoLight from "../assets/logo-light.png";
import navItems from '../constants/navItems';

const Sidebar = ({ drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile, handleSidebarClose }) => {
    const { pathname } = useLocation();
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();
    const username = useSelector(state => state.global.username);
    const company = useSelector(state => state.global.company);
    const role = useSelector(state => state.global.role);
    const themeMode = useSelector(state => state.global.mode);
    const userPermissions = useSelector(state => state.global.permissions);

    useEffect(() => {
        // Normalize pathname to handle routes with hyphens
        setActive(pathname.replace(/^\//, '').replace(/-/g, ' '));
    }, [pathname]);

    // Filter navItems based on role and permissions
    const filteredNavItems = navItems.filter(({ role: itemRole, permissions }) => {
        const shouldDisplayItem = itemRole.includes(role);
        const hasPermission = !permissions || permissions.every(permission => userPermissions.includes(permission));
        return shouldDisplayItem && hasPermission;
    });

    return (
        <Box component="nav">
            {isSidebarOpen && (
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={isSidebarOpen}
                    sx={{
                        width: drawerWidth,
                        "& .MuiDrawer-paper": {
                            backgroundColor: theme.palette.background.paper,
                            boxSizing: "border-box",
                            borderWidth: isNonMobile ? 0 : "2px",
                            width: drawerWidth,
                            overflow: 'hidden',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        },
                    }}
                >
                    <Box sx={{ position: 'relative', height: '100%' }}>
                        <Box
                            sx={{
                                position: 'sticky',
                                top: 0,
                                backgroundColor: theme.palette.background.paper,
                                zIndex: 1,
                                pt: '1rem',
                            }}
                        >
                            <FlexBetween alignItems="center">
                                <Box sx={{ margin: 'auto' }}>
                                    <img src={themeMode === 'light' ? logoLight : logoDark} alt="JTrack" height="60px" />
                                    {!isNonMobile && (
                                        <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                            <ChevronLeft />
                                        </IconButton>
                                    )}
                                </Box>
                            </FlexBetween>
                        </Box>

                        <Box sx={{ height: 'calc(100% - 150px)', overflowY: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}>
                            <List>
                                {filteredNavItems.map(({ text, icon }) => {
                                    // Normalize the nav item text to match the active path
                                    const lcText = text.toLowerCase().replace(/ /g, '-');
                                    const isActive = active === text.toLowerCase();

                                    if (icon === null) {
                                        return (
                                            <ListItem key={text} disablePadding>
                                                <ListItemText disableTypography>
                                                    <Typography variant="subtitle1" sx={{ paddingLeft: '2.5rem', paddingTop: '0.5rem', paddingBottom: '0.25rem' }}>
                                                        {text}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        );
                                    } else {
                                        return (
                                            <ListItem key={text} disablePadding>
                                                <ListItemText disableTypography>
                                                    <Typography
                                                        component="div"
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            backgroundColor: isActive ? theme.palette.primary.main : "transparent",
                                                            color: isActive ? theme.palette.text.black : theme.palette.text.white,
                                                            padding: '0.8rem 2.5rem',
                                                            '&:hover': {
                                                                backgroundColor: theme.palette.secondary.main,
                                                                color: theme.palette.text.black,
                                                            },
                                                        }}
                                                        onClick={() => {
                                                            navigate(`/${lcText}`);
                                                            setActive(text.toLowerCase());
                                                            if (!isNonMobile) {
                                                                handleSidebarClose();
                                                            }
                                                        }}
                                                    >
                                                        {icon}
                                                        <span style={{ flex: 1, marginLeft: '1rem', marginRight: '2rem', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</span>
                                                        {isActive && <ChevronRightOutlined sx={{ position: 'absolute', right: '1rem' }} />}
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        );
                                    }
                                })}
                            </List>
                        </Box>

                        <Box
                            sx={{
                                position: 'sticky',
                                bottom: 0,
                                backgroundColor: theme.palette.background.paper,
                                zIndex: 1,
                            }}
                        >
                            <Divider />
                            <FlexBetween textTransform="none" m="1rem 0" pb="1.5rem">
                                <Box textAlign="center" width="100%">
                                    <Typography
                                        fontWeight="bold"
                                        fontSize="1rem"
                                        sx={{ color: theme.palette.secondary[300] }}
                                    >
                                        {username}
                                    </Typography>
                                    <Typography
                                        fontSize="0.75rem"
                                        sx={{ color: theme.palette.secondary[300] }}
                                    >
                                        {company}
                                    </Typography>
                                </Box>
                            </FlexBetween>
                        </Box>
                    </Box>
                </Drawer>
            )}
        </Box>
    );
};

export default Sidebar;
