import React from 'react';
import { Box } from '@mui/material';
import HourlyBarChart from '../../charts/barCharts/HourlyChart';
import HourlyLineChart from '../../charts/lineCharts/HourlyChart';
import HourlyPieChart from '../../charts/pieCharts/HourlyChart';
import Loading from '../../components/Loading';

const HourlyContent = ({ selectedSensor, processedData, chartIsLoading, error, isSmallScreen, showLabels, tabValue }) => {
    if (!selectedSensor) {
        return <Loading />;
    }

    return (
        <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} width="100%">
            <Box flex={isSmallScreen ? "0" : "1"}>
                <Box height="auto" width={isSmallScreen ? "auto" : "100%"}>
                    <HourlyBarChart
                        selectedMac={selectedSensor}
                        showLabels={showLabels}
                        isSmallScreen={isSmallScreen}
                        processedData={processedData}
                        chartIsLoading={chartIsLoading}
                        error={error}
                        tabValue={tabValue}
                    />
                    <HourlyLineChart
                        selectedMac={selectedSensor}
                        showLabels={showLabels}
                        isSmallScreen={isSmallScreen}
                        processedData={processedData}
                        chartIsLoading={chartIsLoading}
                        error={error}
                        tabValue={tabValue}
                    />
                    <HourlyPieChart
                        selectedMac={selectedSensor}
                        isSmallScreen={isSmallScreen}
                        processedData={processedData}
                        chartIsLoading={chartIsLoading}
                        error={error}
                        tabValue={tabValue}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default HourlyContent;
