import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { customTooltip } from '../components/customTooltip';
import { gradientDefs } from '../components/gradientDefs';

const MonthlyChart = ({ monthlyData, monthlyLoading, selectedSensor, selectedYear, error }) => {
    const themeMode = useSelector(({ global }) => global.mode);

    if (monthlyLoading) {
        return <Loading />;
    }

    // Check for 404 error
    if (error || error?.status === 404 || !monthlyData) {
        return <NoDataAvailableMessage />;
    }

    const selectedYearValue = selectedYear instanceof Date ? selectedYear.getFullYear() : selectedYear.$y || selectedYear;

    const isDataAvailable = monthlyData && Object.keys(monthlyData).length > 0;
    const isSensorDataAvailable = isDataAvailable && monthlyData[selectedSensor];
    const isYearDataAvailable = isSensorDataAvailable && monthlyData[selectedSensor][selectedYearValue];

    if (!isYearDataAvailable) {
        return <NoDataAvailableMessage />;
    }

    const yearData = monthlyData[selectedSensor][selectedYearValue];

    const monthNames = Array.from({ length: 12 }, (_, i) => {
        const month = i + 1;
        return month < 10 ? `0${month}` : `${month}`;
    });

    const uniqueKeys = new Set();

    const chartData = monthNames.map(month => {
        const entry = { month };

        if (yearData.hasOwnProperty(month)) {
            Object.entries(yearData[month]).forEach(([categoryName, value]) => {
                if (categoryName !== 'Total') {
                    entry[categoryName] = value;
                    uniqueKeys.add(categoryName);
                }
            });
        } else {
            Object.keys(yearData[Object.keys(yearData)[0]]).forEach(categoryName => {
                if (categoryName !== 'Total') {
                    entry[categoryName] = 0;
                }
            });
        }

        return entry;
    });

    const keys = Array.from(uniqueKeys);

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <ResponsiveBar
                data={chartData}
                keys={keys}
                indexBy="month"
                margin={{ top: 20, right: 10, bottom: 50, left: 50 }}
                padding={0.25}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Month',
                    legendPosition: 'middle',
                    legendOffset: 45,
                    tickValues: 5,
                    format: value => {
                        const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
                        return monthNames[value - 1];
                    },
                    style: { tick: { fill: '#ffffff' }, legend: { fill: '#ffffff' } }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -40,
                    tickValues: 5,
                    style: { tick: { fill: '#ffffff' }, legend: { fill: '#ffffff' } }
                }}
                enableLabel={false}
                labelSkipWidth={1}
                labelSkipHeight={1}
                labelTextColor="black"
                legends={[]}
                motionConfig="slow"
                theme={{
                    tooltip: {
                        container: {
                            background: '#000',
                            color: '#fff',
                            fontSize: '14px',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            padding: '10px'
                        }
                    },
                    textColor: textColor,
                    axis: {
                        legend: {
                            text: {
                                fill: textColor
                            }
                        },
                        ticks: {
                            text: {
                                fill: textColor
                            }
                        }
                    }
                }}
                tooltip={customTooltip}
                defs={gradientDefs}
                fill={keys.map((name, index) => ({
                    match: { id: name },
                    id: `gradient${index + 1}`,
                }))}
            />
        </div>
    );
};

export default MonthlyChart;
