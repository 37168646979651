import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, useTheme, Slide, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddSiteDialogBox = ({ openDialog, handleCloseDialog, handleInputChange, handleAddSite, siteData, mainLocationsInQatar }) => {
    const theme = useTheme();

    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            onClose={handleCloseDialog}
            aria-describedby="add-device-dialog-description"
            PaperProps={{ sx: { backgroundColor: theme.palette.background.paper } }}
        >
        <DialogTitle variant='h2' fontWeight='bold' sx={{ color: theme.palette.secondary.main }}>
            Add New Site
        </DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="siteName"
                name="name"
                label="Name of Site"
                fullWidth
                value={siteData.name}
                onChange={handleInputChange}
                autoComplete="off"
            />
            <FormControl fullWidth margin="dense">
                <InputLabel id="location-label">Location</InputLabel>
                <Select
                    labelId="location-label"
                    id="location"
                    name="location"
                    value={siteData.location}
                    onChange={handleInputChange}
                >
                    {mainLocationsInQatar.map((location) => (
                    <MenuItem key={location.name} value={location.name}>
                        {location.name}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                margin="dense"
                id="latitude"
                name="latitude"
                label="Latitude"
                fullWidth
                value={siteData.latitude}
                onChange={handleInputChange}
            />
            <TextField
                margin="dense"
                id="longitude"
                name="longitude"
                label="Longitude"
                fullWidth
                value={siteData.longitude}
                onChange={handleInputChange}
            />
        </DialogContent>
        <DialogActions sx={{ paddingBottom: '15px', paddingTop: '0px' }}>
            <Button
                onClick={handleCloseDialog}
                variant="contained"
                sx={{
                    color: theme.palette.text.black,
                    backgroundColor: theme.palette.primary.main,
                    fontWeight: 'bold',
                    '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    },
                    '&:focus': {
                    outline: 'none',
                    }
                }}
            >
                Cancel
            </Button>
            <Button
                onClick={handleAddSite}
                variant="contained"
                sx={{
                    color: theme.palette.text.black,
                    backgroundColor: theme.palette.primary.main,
                    fontWeight: 'bold',
                    '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    },
                    '&:focus': {
                    outline: 'none',
                    }
                }}
            >
                Add
            </Button>
        </DialogActions>
        </Dialog>
    );
};

export default AddSiteDialogBox;