import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import Loading from '../../components/Loading';
import { gradientDefs } from '../components/gradientDefs';
import CustomTooltipPie from '../components/customTooltipPie.js';

const TopDayPieChart = ({ data, isLoading, selectedSensor, selectedYear, selectedMonth, title }) => {
    const themeMode = useSelector(({ global }) => global.mode);
    const chartRef = useRef(null);

    if (isLoading) {
        return <Loading />
    }

    if (!data || !selectedMonth || !selectedYear || !selectedSensor) {
        return <NoDataAvailableMessage />;
    }

    const selectedDateKey = `${selectedYear}-${selectedMonth?.toString().padStart(2, '0')}`;
    const selectedSensorData = data.find(entry => entry.Date.startsWith(selectedDateKey));

    if (!selectedSensorData) {
        return <NoDataAvailableMessage />;
    }

    const filteredKeys = Object.keys(selectedSensorData).filter(key => key !== "Date" && key !== "Total");

    const pieData = filteredKeys.map(key => ({
        id: key,
        value: selectedSensorData[key],
    }));

    const fill = pieData.map((_, index) => ({
        match: { id: pieData[index].id },
        id: `gradient${index + 1}`
    }));

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    return (
        <div style={{ position: 'relative', height: 270 }}>
            <div ref={chartRef} style={{ position: 'relative', width: '100%', height: '100%' }}>
                <ResponsivePie
                    data={pieData}
                    margin={{ top: 20, left: 0, right: 0, bottom: 20 }}
                    startAngle={-150}
                    innerRadius={0.65}
                    activeOuterRadiusOffset={8}
                    arcLinkLabelsTextColor={textColor}
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={textColor}
                    arcLinkLabel="value"
                    enableArcLabels={false}
                    arcLabelsTextColor={{ from: textColor, modifiers: [['darker', 3]] }}
                    defs={gradientDefs}
                    fill={fill}
                    tooltip={({ datum }) => <CustomTooltipPie datum={datum} />}
                    legends={[]}
                />
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', width: '100%', pointerEvents: 'none' }}>
                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>{title}<br />{selectedSensorData.Date}</span>
                </div>
            </div>
        </div>
    );
};

export default TopDayPieChart;
