export const tokensDark = {
  black: '#000000',
  white: '#fff',
  grey: '#191919',
  primary: '#9A7CF8',
  secondary: '#60B478',
};

export const tokensLight = {
  black: '#000000',
  white: '#fff',
  grey: '#F3F6F4',
  primary: '#9A7CF8',
  secondary: '#60B478',
};

export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: tokensDark.primary,
            },
            secondary: {
              main: tokensDark.secondary,
            },
            text: {
              white: tokensDark.white,
              black: tokensDark.black,
            },
            background: {
              default: tokensDark.black,
              paper: tokensDark.grey,
            },
          }
        : {
            primary: {
              main: tokensLight.primary,
            },
            secondary: {
              main: tokensLight.secondary,
            },
            text: {
              white: tokensLight.black,
              black: tokensLight.black,
            },
            background: {
              default: tokensLight.grey,
              paper: tokensLight.white,
            },
          }),
    },
    typography: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};
