import React from 'react';
import { FormControlLabel, Checkbox, Box } from '@mui/material';

const ShowLabelsCheckbox = ({ showLabels, onChange, isSmallScreen }) => {
    return (
        <Box sx={{ textAlign: isSmallScreen ? 'center' : 'left', marginTop: isSmallScreen ? 0.5 : 0 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showLabels}
                        onChange={onChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'Show Labels' }}
                    />
                }
                label={<span style={{ fontWeight: 'bold' }}>Show Labels</span>}
            />
        </Box>
    );
};

export default ShowLabelsCheckbox;
