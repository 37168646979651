import React from 'react';
import Header from '../../components/Header';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Box, useTheme } from '@mui/material';

const HomepageHeader = () => {
    const username = useSelector(state => state.global.username);
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });
    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            justifyContent="space-between"
            marginBottom="1rem"
            padding="0.5rem"
            backgroundColor={theme.palette.background.paper}
            borderRadius='15px'
        >
            <Header title={`Hey, ${username}`} subtitle="Welcome to your dashboard"/>
        </Box>
    )
}

export default HomepageHeader;