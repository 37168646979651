import React from 'react';
import { Paper } from '@mui/material';

const SensorIframe = ({ sensorLabel, isSmallScreen }) => {
    const getIframeSrc = () => {
        const firstPart = sensorLabel.split(' ')[0];
        return `https://media.publit.io/file/${firstPart}.html`;
    };

    return (
        <Paper style={{ 
            marginBottom: isSmallScreen ? '0.5rem' : '0', 
            position: 'relative', 
            paddingTop: '56.25%', 
            borderRadius: '15px',
            overflow: 'hidden'
        }}>
            <iframe
                src={getIframeSrc()}
                title="Bridge"
                frameBorder="0"
                scrolling="no"
                border="0"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 'none'
                }}
            />
        </Paper>
    );
};

export default SensorIframe;
