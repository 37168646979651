import React from 'react';
import { Box, Button } from '@mui/material';
import { AddBoxOutlined } from '@mui/icons-material';
import Header from '../../components/Header';

const DevicesHeader = ({ isAdmin, handleOpenDialog, theme }) => (
  <Box
      display="flex"
      justifyContent="space-between"
      marginBottom="1rem"
      padding="0.5rem"
      backgroundColor={theme.palette.background.paper}
      borderRadius="15px"
    >
    <Header title="DEVICES" subtitle="View your list of devices." />
    {isAdmin && (
      <Button
          onClick={handleOpenDialog}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.black,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:focus': {
              outline: 'none',
            },
            m: '0.5rem 0.5rem'
          }}
        >
        <AddBoxOutlined sx={{ marginRight: "8px" }} />
        Add Device
      </Button>
    )}
  </Box>
);

export default DevicesHeader;
