import React from 'react';
import { Box } from '@mui/material';
import MonthlyBarChart from '../../charts/barCharts/MonthlyChart';
import MonthlyLineChart from '../../charts/lineCharts/MonthlyChart';
import MonthlyPieChart from '../../charts/pieCharts/MonthlyChart';
import Loading from '../../components/Loading';

const MonthlyContent = ({ selectedSensor, selectedYearRange, showLabels, processedData, chartIsLoading, error, isSmallScreen, tabValue }) => {

    if (!selectedSensor) {
        return <Loading />;
    }

    return (
        <>
            <Box height="auto" width={isSmallScreen ? "auto" : "100%"}>
                {selectedSensor && selectedYearRange[0] ? (
                    <>
                        <MonthlyBarChart 
                            selectedSensor={selectedSensor}
                            selectedYear={selectedYearRange[0]}
                            showLabels={showLabels}
                            isSmallScreen={isSmallScreen}
                            processedData={processedData}
                            chartIsLoading={chartIsLoading}
                            error={error}
                            tabValue={tabValue}
                        />
                        <MonthlyLineChart 
                            selectedSensor={selectedSensor}
                            selectedYear={selectedYearRange[0]}
                            showLabels={showLabels}
                            isSmallScreen={isSmallScreen}
                            processedData={processedData}
                            chartIsLoading={chartIsLoading}
                            error={error}
                            tabValue={tabValue}
                        />
                        <MonthlyPieChart
                            selectedSensor={selectedSensor}
                            selectedYear={selectedYearRange[0]}
                            showLabels={showLabels}
                            isSmallScreen={isSmallScreen}
                            processedData={processedData}
                            chartIsLoading={chartIsLoading}
                            error={error}
                            tabValue={tabValue}
                        />
                    </>
                ) : (
                    <Loading />
                )}
            </Box>
        </>
    );
};

export default MonthlyContent;
