import React from 'react';
import { Box, useTheme, Button } from '@mui/material';
import Header from '../../components/Header';
import { AddBoxOutlined } from '@mui/icons-material';

const AdminManagementHeader = ({ handleOpenDialog, isSmallScreen }) => {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" gap="1rem">
            {isSmallScreen ? (
                <>
                    <Box 
                        display="flex" 
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="0.5rem"
                    >
                        <Box flexGrow={1}>
                            <Header title="ADMIN MANAGEMENT" subtitle="View and manage all your admins" />
                        </Box>
                    </Box>
                    <Box 
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="1rem"
                        gap="1rem"
                    >
                        <Button
                            onClick={handleOpenDialog}
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.text.black,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "13px 20px",
                                width: "auto",
                                margin: "0 auto",
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        >
                            <AddBoxOutlined sx={{ marginRight: "8px" }} />
                            Create Power Admin
                        </Button>
                    </Box>
                </>
            ) : (
                <Box 
                    display="flex" 
                    justifyContent="space-between" 
                    width="100%" 
                    backgroundColor={theme.palette.background.paper} 
                    borderRadius='15px' 
                    padding="0.5rem"
                >
                    <Box flexGrow={1}>
                        <Header title="ADMIN MANAGEMENT" subtitle="View and manage all your admins" />
                    </Box>
                    <Box display="flex" alignItems="center" gap="1rem">
                        <Button
                            onClick={handleOpenDialog}
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.text.black,
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "13px 20px",
                                minWidth: "200px",
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        >
                            <AddBoxOutlined sx={{ marginRight: "8px" }} />
                            Create Power Admin
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default AdminManagementHeader;
