import React from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography, Box, useTheme } from '@mui/material';

const TopTable = ({ title, data, selectedMonth, selectedSensor }) => {
    const theme = useTheme();

    const renderDataRows = () => {
        const rows = [];
        const fullSelectedMonth = `2024-${selectedMonth < 10 ? `0${selectedMonth}` : selectedMonth}`;
        const monthData = data.find(entry => entry.Date.startsWith(fullSelectedMonth));

        if (monthData) {
            for (const key in monthData) {
                if (key !== 'Date' && key !== 'Total') {
                    rows.push(
                        <TableRow key={key}>
                            <TableCell align="center">{key}</TableCell>
                            <TableCell align="center">{monthData[key]}</TableCell>
                            <TableCell align="center">{monthData.Date}</TableCell>
                        </TableRow>
                    );
                }
            }
        }

        return rows;
    };

    return (
        <Box
            backgroundColor={theme.palette.background.paper}
            borderRadius="0.55rem"
            display="flex"
            flexDirection="column"
            position="relative"
        >
            <Typography variant="h3" sx={{ color: theme.palette.secondary.main, m: "2%", fontWeight: 'bold' }}>{title}</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Logic Name</TableCell>
                            <TableCell align="center">Count</TableCell>
                            <TableCell align="center">Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderDataRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default TopTable;
