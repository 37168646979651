import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@mui/material';

const CompanyUsersTable = ({ sortedUserData, orderBy, order, createSortHandler }) => {
    // Filter out users with roles "Super Admin" and "Power Admin"
    const filteredUserData = sortedUserData.filter(user => 
        user.role?.name !== 'Super Admin' && user.role?.name !== 'Power Admin'
    );

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'name'}
                            direction={orderBy === 'name' ? order : 'asc'}
                            onClick={createSortHandler('name')}
                        >
                            Name
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'email'}
                            direction={orderBy === 'email' ? order : 'asc'}
                            onClick={createSortHandler('email')}
                        >
                            Email
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Active</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredUserData.map(user => (
                    <TableRow key={user._id}>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.role?.name || 'No role assigned'}</TableCell>
                        <TableCell>{user.active ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default CompanyUsersTable;
