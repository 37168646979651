import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { gradientDefs } from '../../charts/components/gradientDefs';
import { customTooltip } from '../../charts/components/customTooltip';

const GrowthOverviewChart = ({ yearsOfCompanyCreation, isLoading }) => {
    const themeMode = useSelector(({ global }) => global.mode);

    // Check if loading or data is unavailable
    if (isLoading || !yearsOfCompanyCreation || Object.keys(yearsOfCompanyCreation).length === 0) {
        return (
            <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    // Get the last five years as strings
    const lastFiveYears = Array.from({ length: 5 }, (_, index) => {
        const currentYear = new Date().getFullYear();
        return (currentYear - index).toString();
    });

    // Process data for chart
    const chartData = lastFiveYears.map(year => ({
        year,
        companies: yearsOfCompanyCreation[year] || 0,
    }));

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ResponsiveBar
                data={chartData}
                keys={['companies']}
                indexBy="year"
                margin={{ top: 25, right: 30, bottom: 60, left: 60 }}
                padding={0.25}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Year',
                    legendPosition: 'middle',
                    legendOffset: 36,
                    tickValues: lastFiveYears,
                    style: {
                        tick: { fill: textColor },
                        legend: { fill: textColor }
                    }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -40,
                    tickValues: 5,
                    style: {
                        tick: { fill: textColor },
                        legend: { fill: textColor }
                    }
                }}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={textColor}
                legends={[]}
                tooltip={customTooltip}
                motionConfig="slow"
                theme={{
                    tooltip: {
                        container: {
                            background: '#000',
                            color: '#fff',
                            fontSize: '14px',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            padding: '10px'
                        }
                    },
                    textColor: textColor,
                    axis: {
                        legend: {
                            text: {
                                fill: textColor
                            }
                        },
                        ticks: {
                            text: {
                                fill: textColor
                            }
                        }
                    }
                }}
                defs={gradientDefs}
                fill={[{ match: { id: 'companies' }, id: 'gradient1' }]}
            />
        </div>
    );
};

export default GrowthOverviewChart;
