import React, { useMemo, useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, Typography, IconButton, Box, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RenewLicenseDialogBox from './RenewLicenseDialogBox';
import { useAddLicensesMutation } from '../../services/api';

const LicenseTable = ({ licencesData, packagesData, refetchLicensesData, isSmallScreen }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('startDate');
    const [open, setOpen] = useState(false);
    const [selectedLicense, setSelectedLicense] = useState(null);
    const theme = useTheme();

    const [updateLicense] = useAddLicensesMutation();

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(property);
    };

    const sortedData = useMemo(() => {
        if (licencesData) {
            return [...licencesData].sort((a, b) => {
                const valueA = a[orderBy] || '';
                const valueB = b[orderBy] || '';
                if (order === 'asc') {
                    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
                } else {
                    return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
                }
            });
        }
        return [];
    }, [licencesData, orderBy, order]);

    const getPackageStatus = (license) => {
        if (license.package?.durationInDays === -1) {
            return 'Active';
        }
        const currentDate = new Date();
        const endDate = new Date(license.endDate);
        return endDate >= currentDate ? 'Active' : 'Expired';
    };

    const calculateRemainingDays = (license) => {
        if (license.package?.durationInDays === -1) {
            return '-';
        }
        if (!license.endDate) return 'N/A';
        const currentDate = new Date();
        const end = new Date(license.endDate);
        const timeDiff = end - currentDate;
        return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    };

    const handleOpenDialog = (license) => {
        setSelectedLicense(license);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedLicense(null);
    };

    const handleAddLicenses = async (updatedPackage) => {
        if (selectedLicense) {
            const { _id, device, company } = selectedLicense;
            try {
                await updateLicense({
                    _id,
                    deviceId: device ? device._id : '',
                    companyId: company ? company._id : '',
                    packageId: updatedPackage,
                });
                showSnackbar('License updated successfully', 'success');
                handleCloseDialog();
                refetchLicensesData();
            } catch (error) {
                showSnackbar('Failed to update license', 'error');
            }
        }
    };

    const showSnackbar = (message, type) => {
        // Implement snackbar logic here
    };

    return (
        <>
            <Box
                backgroundColor={theme.palette.background.paper}
                borderRadius='15px'
                sx={{
                    overflowX: isSmallScreen ? 'auto' : 'hidden',
                    '&::-webkit-scrollbar': {
                        display: 'none'
                    },
                    '-ms-overflow-style': 'none',
                    scrollbarWidth: 'none'
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'device.name'}
                                    direction={orderBy === 'device.name' ? order : 'asc'}
                                    onClick={createSortHandler('device.name')}
                                >
                                    Device
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'company.name'}
                                    direction={orderBy === 'company.name' ? order : 'asc'}
                                    onClick={createSortHandler('company.name')}
                                >
                                    Company
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'package.name'}
                                    direction={orderBy === 'package.name' ? order : 'asc'}
                                    onClick={createSortHandler('package.name')}
                                >
                                    Package
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'package.isActive'}
                                    direction={orderBy === 'package.isActive' ? order : 'asc'}
                                    onClick={createSortHandler('package.isActive')}
                                >
                                    Status
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'startDate'}
                                    direction={orderBy === 'startDate' ? order : 'asc'}
                                    onClick={createSortHandler('startDate')}
                                >
                                    Start Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'endDate'}
                                    direction={orderBy === 'endDate' ? order : 'asc'}
                                    onClick={createSortHandler('endDate')}
                                >
                                    End Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'remainingDays'}
                                    direction={orderBy === 'remainingDays' ? order : 'asc'}
                                    onClick={createSortHandler('remainingDays')}
                                >
                                    Remaining Days
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Renew License</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((license) => {
                            const status = getPackageStatus(license);
                            const statusColor = status === 'Active' ? 'green' : 'red';
                            const remainingDays = calculateRemainingDays(license);
                            const endDateDisplay = license.package?.durationInDays === -1 ? 'Lifetime' : new Date(license.endDate).toLocaleDateString();

                            return (
                                <TableRow key={license._id}>
                                    <TableCell>{license.device?.name || 'N/A'}</TableCell>
                                    <TableCell>{license.company?.name || 'N/A'}</TableCell>
                                    <TableCell>{license.package?.name || 'N/A'}</TableCell>
                                    <TableCell>
                                        <Typography style={{ color: statusColor, fontWeight: 'bold' }}>
                                            {status}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{license.startDate ? new Date(license.startDate).toLocaleDateString() : 'N/A'}</TableCell>
                                    <TableCell>{endDateDisplay}</TableCell>
                                    <TableCell>{remainingDays}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleOpenDialog(license)} sx={{ '&:focus': { outline: 'none' } }}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
            <RenewLicenseDialogBox
                open={open}
                onClose={handleCloseDialog}
                packagesData={packagesData}
                selectedPackage={selectedLicense?.package?._id || ''}
                handlePackageChange={(e) => setSelectedLicense(prev => ({ ...prev, package: { _id: e.target.value } }))}
                handleSave={handleAddLicenses}
                showSnackbar={showSnackbar}
            />
        </>
    );
};

export default LicenseTable;
