import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Loading from './Loading';

const NoDataAvailableMessage = () => {
    const theme = useTheme();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Box
            marginTop="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="50vh"
        >
            {loading ? (
                <Loading />
            ) : (
                <Typography variant="h1" sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                    NO DATA AVAILABLE
                </Typography>
            )}
        </Box>
    );
};
export default NoDataAvailableMessage;