import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDevice } from '../services/state/globalSlice';

const useSelectedSensor = (sensorOptions, selectedCompany) => {
    const { selectedDevice } = useSelector(state => state.global);
    const dispatch = useDispatch();
    
    const [selectedSensor, setSelectedSensor] = useState(selectedDevice || "");

    const handleSensorChange = useCallback((event) => {
        const newSelectedDevice = event.target.value;
        setSelectedSensor(newSelectedDevice);
        dispatch(setSelectedDevice(newSelectedDevice));
    }, [dispatch]);

    useEffect(() => {
        if (sensorOptions.length > 0) {
            const initialSelectedDevice = selectedDevice || sensorOptions[0].mac;
            setSelectedSensor(initialSelectedDevice);
            dispatch(setSelectedDevice(initialSelectedDevice));
        }
    }, [sensorOptions, selectedCompany, dispatch, selectedDevice]);

    useEffect(() => {
        if (sensorOptions.length > 0) {
            const newSelectedSensor = sensorOptions.find(option => option.mac === selectedSensor);
            if (!newSelectedSensor) {
                const firstSensor = sensorOptions[0].mac;
                setSelectedSensor(firstSensor);
                dispatch(setSelectedDevice(firstSensor));
            }
        }
    }, [sensorOptions, selectedCompany, dispatch, selectedSensor]);

    const getSelectedSensorLabel = useCallback(() => {
        return sensorOptions.find(option => option.mac === selectedSensor)?.sensorName || "";
    }, [sensorOptions, selectedSensor]);

    return { selectedSensor, handleSensorChange, getSelectedSensorLabel };
};

export default useSelectedSensor;
