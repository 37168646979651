import React from 'react';
import { Box, Typography, Tabs, Tab, useTheme, Select, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';

const ReportLabel = ({ sensorLabel, isSmallScreen, tabValue, onTabChange }) => {
    const theme = useTheme();

    const permissions = useSelector((state) => state.global.permissions);

    const hasAdultChildPermission = permissions.includes('view_adult_child');

    const handleTabChange = (event, newValue) => {
        onTabChange(newValue);
    };

    const handleDropdownChange = (event) => {
        onTabChange(parseInt(event.target.value, 10));
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb='1rem'
            p={isSmallScreen ? '0' : '0.5rem'}
            backgroundColor={theme.palette.background.paper}
            borderRadius="15px"
        >
            <Typography
                variant={isSmallScreen ? "h3" : "h2"}
                component="div"
                sx={{
                    color: theme.palette.secondary.main,
                    fontWeight: "bold",
                    textAlign: "left",
                    ml: isSmallScreen ? '0.5rem' : '0',
                }}
            >
                {sensorLabel}
            </Typography>
            {isSmallScreen ? (
                <Select
                    value={tabValue}
                    onChange={handleDropdownChange}
                    variant="outlined"
                    sx={{
                        fontSize: isSmallScreen ? 'h6.fontSize' : 'h4.fontSize',
                        fontWeight: 'bold',
                        color: theme.palette.secondary.main,
                        height: '2.5rem',
                        margin: '0.5rem'
                    }}
                >
                    <MenuItem value={0}>TOTAL</MenuItem>
                    {hasAdultChildPermission && <MenuItem value={1}>ADULT & CHILD</MenuItem>}
                </Select>
            ) : (
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="secondary"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: theme.palette.primary.main,
                        },
                    }}
                >
                    <Tab
                        label="TOTAL"
                        sx={{
                            typography: isSmallScreen ? 'h6' : 'h4',
                            fontWeight: 'bold',
                            color: tabValue === 0 ? theme.palette.primary.main : theme.palette.secondary.main,
                            '&.Mui-selected': {
                                color: theme.palette.primary.main,
                            },
                            '&:focus': {
                                outline: 'none',
                            },
                        }}
                    />
                    {hasAdultChildPermission && (
                        <Tab
                            label="ADULT & CHILD"
                            sx={{
                                typography: isSmallScreen ? 'h6' : 'h4',
                                fontWeight: 'bold',
                                color: tabValue === 1 ? theme.palette.primary.main : theme.palette.secondary.main,
                                '&.Mui-selected': {
                                    color: theme.palette.primary.main,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        />
                    )}
                </Tabs>
            )}
        </Box>
    );
};

export default ReportLabel;