import React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { AddBoxOutlined } from '@mui/icons-material';
import Header from '../../components/Header';

const UsersHeader = ({ role, handleOpenDialog, isSmallScreen }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      marginBottom="1rem"
      padding="0.5rem"
      backgroundColor={theme.palette.background.paper}
      borderRadius="15px"
    >
      <Header title="USERS" subtitle="See your users" />
      {role === 'Admin' && (
        <Button
          onClick={handleOpenDialog}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.black,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:focus': {
              outline: 'none',
            },
            m: '0.5rem 0.5rem'
          }}
        >
          <AddBoxOutlined sx={{ marginRight: "10px" }} />
          Add Users
        </Button>
      )}
    </Box>
  );
};

export default UsersHeader;
