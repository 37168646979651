import { linearGradientDef } from '@nivo/core';

export const customColorScheme = ['#28A3FE', '#8158F6', '#FC5D2A', '#BD1313', '#56b340', '#e7c070', '#4a57f9', '#c43da6'];

export const gradientDefs = [
    linearGradientDef('gradient1', [
        { offset: 0, color: '#28CBFE' },
        { offset: 100, color: '#2061cb' }
    ]),
    linearGradientDef('gradient2', [
        { offset: 0, color:  '#a46af7'},
        { offset: 100, color: '#2e227a' }
    ]),
    linearGradientDef('gradient3', [
        { offset: 0, color: '#FC8F2A' },
        { offset: 100, color: '#FB2A29' }
    ]),
    linearGradientDef('gradient4', [
        { offset: 0, color: '#fc1919' },
        { offset: 100, color: '#7e0c0c' }
    ]),
    linearGradientDef('gradient5', [
        { offset: 0, color: '#7ed957' },
        { offset: 100, color: '#2d8c28' }
    ]),
    linearGradientDef('gradient6', [
        { offset: 0, color: '#fddb92' },
        { offset: 100, color: '#d1a54d' }
    ]),
    linearGradientDef('gradient7', [
        { offset: 0, color: '#6a73f7' },
        { offset: 100, color: '#2a3afb' }
    ]),
    linearGradientDef('gradient8', [
        { offset: 0, color: '#f753c6' },
        { offset: 100, color: '#912785' }
    ])
];
