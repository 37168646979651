import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import { gradientDefs } from '../components/gradientDefs';
import Loading from '../../components/Loading';
import CustomTooltipPie from '../components/customTooltipPie';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useTheme } from '@mui/material';

const HourlyPieChart = ({ chartIsLoading, selectedMac, processedData, error, isSmallScreen, tabValue }) => {
    const [activeLegends, setActiveLegends] = useState([]);
    const themeMode = useSelector(({ global }) => global.mode);
    const theme = useTheme();

    if (chartIsLoading) {
        return (
            <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    if (error || error?.status === 404 || !processedData || Object.keys(processedData).length === 0) {
        return <NoDataAvailableMessage />;
    }

    const hourlyData = processedData[selectedMac];

    if (!hourlyData || Object.keys(hourlyData).length === 0) {
        return <NoDataAvailableMessage />;
    }

    // Aggregate the data for the pie chart
    const aggregateData = {};
    Object.values(hourlyData).forEach(hourCounts => {
        Object.entries(hourCounts).forEach(([logic, count]) => {
            if (!aggregateData[logic]) {
                aggregateData[logic] = 0;
            }
            aggregateData[logic] += count;
        });
    });

    const pieData = Object.entries(aggregateData).map(([key, value]) => ({
        id: key,
        value,
    }));

    const fill = pieData.map((_, index) => ({
        match: { id: pieData[index].id },
        id: `gradient${index + 1}`,
    }));

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const customLegendItems = pieData.map((d, index) => ({
        id: d.id,
        label: d.id,
        color: `url(#gradient${index + 1})`,
    }));

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.id)
                ? prevActiveLegends.filter((item) => item !== legendItem.id)
                : [...prevActiveLegends, legendItem.id]
        );
    };

    const filteredPieData = pieData.filter((d) => !activeLegends.includes(d.id));

    return (
        <div style={{
            position: 'relative',
            height: isSmallScreen ? 375 : 600,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '15px',
            marginTop: '1rem',
        }}>
            <ResponsivePie
                data={filteredPieData}
                margin={isSmallScreen ? { top: -50, left: 65, right: 65, bottom: 0 } : { top: 75, left: 0, right: 0, bottom: 100 }}
                startAngle={-150}
                innerRadius={0.5}
                activeOuterRadiusOffset={8}
                arcLinkLabelsDiagonalLength={9}
                arcLinkLabelsTextColor={textColor}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={textColor}
                arcLinkLabel="value"
                enableArcLabels={false}
                arcLabelsTextColor={{ from: textColor, modifiers: [['darker', 3]] }}
                defs={gradientDefs}
                fill={fill}
                tooltip={({ datum }) => <CustomTooltipPie datum={datum} />}
                legends={isSmallScreen ? [
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: tabValue === 1 ? 0 : 20,
                        translateY: -35,
                        itemsSpacing: tabValue === 1 ? 10 : 2,
                        itemWidth: tabValue === 1 ? 80 : 75,
                        itemHeight: 10,
                        itemTextColor: textColor,
                        itemDirection: 'left-to-right',
                        symbolSize: 12,
                        symbolShape: 'square',
                        itemOpacity: 0.85,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ],
                        data: customLegendItems,
                        onClick: handleLegendClick,
                    }
                ] : [
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 85,
                        itemsSpacing: 50,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ],
                        data: customLegendItems,
                        onClick: handleLegendClick,
                    }
                ]}
            />
        </div>
    );
};

export default HourlyPieChart;
