import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCompany } from '../services/state/globalSlice';

const useSelectedCompany = ({ companyOptions }) => {
    const dispatch = useDispatch();
    const selectedCompanyFromStore = useSelector(state => state.global.selectedCompany);
    const { role, assignedCompanies } = useSelector(state => state.global);
    
    const [localSelectedCompany, setLocalSelectedCompany] = useState("");

    const handleCompanyChange = useCallback((event) => {
        const newSelectedCompany = event.target.value;
        setLocalSelectedCompany(newSelectedCompany);
        dispatch(setSelectedCompany(newSelectedCompany));
    }, [dispatch]);

    useEffect(() => {
        let filteredCompanyOptions = companyOptions;

        if (role === 'Power Admin') {
            filteredCompanyOptions = companyOptions.filter(option => assignedCompanies.includes(option.name));
        }

        if (!selectedCompanyFromStore && filteredCompanyOptions.length > 0) {
            const initialSelectedCompany = filteredCompanyOptions[0].name;
            setLocalSelectedCompany(initialSelectedCompany);
            dispatch(setSelectedCompany(initialSelectedCompany));
        } else if (selectedCompanyFromStore && filteredCompanyOptions.length > 0) {
            const selectedOption = filteredCompanyOptions.find(option => option.name === selectedCompanyFromStore);
            if (selectedOption) {
                setLocalSelectedCompany(selectedOption.name);
            } else {
                const initialSelectedCompany = filteredCompanyOptions[0].name;
                setLocalSelectedCompany(initialSelectedCompany);
                dispatch(setSelectedCompany(initialSelectedCompany));
            }
        }
    }, [selectedCompanyFromStore, companyOptions, role, assignedCompanies, dispatch]);

    const getSelectedCompanyLabel = useCallback(() => {
        const selectedOption = companyOptions.find(option => option.name === localSelectedCompany);
        return selectedOption ? selectedOption.name : "";
    }, [companyOptions, localSelectedCompany]);

    return {
        selectedCompany: localSelectedCompany,
        handleCompanyChange,
        getSelectedCompanyLabel,
    };
};

export default useSelectedCompany;