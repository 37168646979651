import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetUserQuery, useUpdateUserMutation, useChangePasswordMutation } from "../../services/api";
import ErrorSnackbar from '../../components/ErrorSnackbar';
import { useMediaQuery } from 'react-responsive';

const Settings = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    const userId = useSelector(state => state.global.id);
    const { data: userData } = useGetUserQuery(userId);
    const [updateProfile] = useUpdateUserMutation();
    const [changePassword] = useChangePasswordMutation();

    const [userDetails, setUserDetails] = useState({
        name: '',
        email: '',
        company: '',
        country: '',
        phoneNumber: '',
    });

    const [passwordDetails, setPasswordDetails] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

    useEffect(() => {
        if (userData && userData.user && Object.keys(userData.user).length > 0) {
            setUserDetails({
                name: userData.user.name || '',
                email: userData.user.email || '',
                company: userData.user.company || '',
                country: userData.user.country || '',
                phoneNumber: userData.user.phoneNumber || '',
            });
        }
    }, [userData]);

    const handleInputChange = (e, field) => {
        setUserDetails({ ...userDetails, [field]: e.target.value });
    };

    const handlePasswordChange = (e, field) => {
        setPasswordDetails({ ...passwordDetails, [field]: e.target.value });
    };

    const handleUpdateProfile = async () => {
        try {
            const { error } = await updateProfile({ ...userDetails, id: userId });

            if (error) {
                setSnackbar({
                    open: true,
                    message: 'Error updating profile',
                    severity: 'error',
                });
                console.error('Error updating profile:', error);
                return;
            }

            setSnackbar({
                open: true,
                message: 'Profile updated successfully',
                severity: 'success',
            });
            console.log('Profile updated successfully:');
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Error updating profile',
                severity: 'error',
            });
            console.error('Error updating profile:', error);
        }
    };

    const handleChangePassword = async () => {
        try {
            const { oldPassword, newPassword, confirmPassword } = passwordDetails;

            if (!oldPassword || !newPassword || !confirmPassword) {
                setSnackbar({
                    open: true,
                    message: 'Please fill in all password fields',
                    severity: 'error',
                });
                return;
            }

            if (newPassword !== confirmPassword) {
                setSnackbar({
                    open: true,
                    message: 'New password and confirm password do not match',
                    severity: 'error',
                });
                return;
            }

            const { error } = await changePassword({
                userId,
                oldPassword,
                newPassword,
            });

            if (error) {
                setSnackbar({
                    open: true,
                    message: 'Invalid Old Password',
                    severity: 'error',
                });
                console.error('Error changing password:', error);
                return;
            }

            setSnackbar({
                open: true,
                message: 'Password changed successfully',
                severity: 'success',
            });
            console.log('Password changed successfully:');
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Error changing password',
                severity: 'error',
            });
            console.error('Error changing password:', error);
        }
    };

    const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

    return (
        <Box mx="auto" width={isSmallScreen ? "75%" : "50%"} pb={1}>
            <Box>
                <Box colxxl={12} mt={3}>
                    <Box my={5} mb={3}>
                        <Typography variant="h3" sx={{ fontSize: '2rem', fontWeight: 'bold', color: theme.palette.secondary.main }}>
                            My Profile
                        </Typography>
                        <hr style={{ height: '3px', backgroundColor: theme.palette.secondary.main, border: 'none', width: '100%' }} />
                    </Box>

                    <form className="file-upload">
                        <Box mb={5} mx={-4}>
                            <Box colxxl={8} mb={{ md: 5, xxl: 0 }}>
                                <Box
                                    p={4}
                                    borderRadius={5}
                                    mb={3}
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        color: theme.palette.secondary.main,
                                    }}
                                >
                                    <Typography variant="h4" mb={3} mt={0}>
                                        Edit My Details
                                    </Typography>

                                    {Object.entries(userDetails).map(([key, value]) => (
                                        <Box key={key} md={6} mb={2}>
                                            <Typography>{key.charAt(0).toUpperCase() + key.slice(1)}</Typography>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                value={value}
                                                onChange={(e) => handleInputChange(e, key)}
                                            />
                                        </Box>
                                    ))}

                                    <Box
                                        display="flex"
                                        justifyContent={{ md: 'flex-end' }}
                                        alignItems="center"
                                        gap={3}
                                        mt={2}
                                    >
                                        <Button
                                            onClick={handleUpdateProfile}
                                            variant="contained"
                                            sx={{
                                                mt: '10px',
                                                color: theme.palette.text.black,
                                                backgroundColor: theme.palette.primary.main,
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    backgroundColor: theme.palette.secondary.dark,
                                                },
                                                '&:focus': {
                                                    outline: 'none',
                                                }
                                            }}
                                        >
                                            Update Profile
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box mb={5} mx={-4}>
                            <Box colxxl={6}>
                                <Box
                                    p={4}
                                    borderRadius={5}
                                    mb={3}
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                        color: theme.palette.secondary.main,
                                    }}
                                >
                                    <Box g={3}>
                                        <Typography variant="h4" mb={3}>
                                            Change Password
                                        </Typography>

                                        <Box md={6} mb={2}>
                                            <Typography htmlFor="oldPassword" className="form-label">
                                                Old password
                                            </Typography>
                                            <TextField
                                                type="password"
                                                variant="outlined"
                                                fullWidth
                                                id="oldPassword"
                                                value={passwordDetails.oldPassword}
                                                onChange={(e) => handlePasswordChange(e, 'oldPassword')}
                                            />
                                        </Box>

                                        <Box md={6} mb={2}>
                                            <Typography htmlFor="newPassword" className="form-label">
                                                New password
                                            </Typography>
                                            <TextField
                                                type="password"
                                                variant="outlined"
                                                fullWidth
                                                id="newPassword"
                                                value={passwordDetails.newPassword}
                                                onChange={(e) => handlePasswordChange(e, 'newPassword')}
                                            />
                                        </Box>

                                        <Box md={12} mb={2}>
                                            <Typography htmlFor="confirmPassword" className="form-label">
                                                Confirm Password
                                            </Typography>
                                            <TextField
                                                type="password"
                                                variant="outlined"
                                                fullWidth
                                                id="confirmPassword"
                                                value={passwordDetails.confirmPassword}
                                                onChange={(e) => handlePasswordChange(e, 'confirmPassword')}
                                            />
                                        </Box>
                                    </Box>

                                    <Box
                                        display="flex"
                                        justifyContent={{ md: 'flex-end' }}
                                        alignItems="center"
                                        gap={3}
                                        mt={2}
                                    >
                                        <Button
                                            onClick={handleChangePassword}
                                            variant="contained"
                                            sx={{
                                                mt: '10px',
                                                color: theme.palette.text.black,
                                                backgroundColor: theme.palette.primary.main,
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    backgroundColor: theme.palette.secondary.dark,
                                                },
                                                '&:focus': {
                                                    outline: 'none',
                                                }
                                            }}
                                        >
                                            Change Password
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Box>
            <ErrorSnackbar
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Box>
    );
};

export default Settings;