import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { gradientDefs } from '../components/gradientDefs';
import { customTooltip } from '../components/customTooltip';

const DashboardYearlyChart = ({ isLoading, processedData }) => {
    const themeMode = useSelector(({ global }) => global.mode);

    if ( isLoading || !processedData || Object.keys(processedData).length === 0) {
        return (
            <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const lastFiveYears = Array.from({ length: 5 }, (_, index) => {
        const currentYear = new Date().getFullYear();
        return (currentYear - index).toString();
    });

    const chartData = lastFiveYears.map(year => {
        const yearData = processedData[year] || {};
        return {
            year,
            ...yearData
        };
    });

    const firstYearData = processedData[lastFiveYears[0]] || {};
    const keys = Object.keys(firstYearData);

    return (
        <div style={{ height: '300px', width: '100%' }}>
            <ResponsiveBar
                data={chartData}
                keys={keys.filter(key => key !== 'total')}
                indexBy="year"
                margin={{ top: 10, right: 30, bottom: 60, left: 60 }}
                padding={0.25}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Year',
                    legendPosition: 'middle',
                    legendOffset: 36,
                    tickValues: lastFiveYears,
                    tickFormat: value => value,
                    style: {
                        tick: { fill: textColor },
                        legend: { fill: textColor }
                    }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -40,
                    tickValues: 5,
                    style: {
                        tick: { fill: textColor },
                        legend: { fill: textColor }
                    }
                }}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={textColor}
                legends={[]}
                tooltip={customTooltip}
                motionConfig="slow"
                theme={{
                    tooltip: {
                        container: {
                            background: '#000',
                            color: '#fff',
                            fontSize: '14px',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            padding: '10px'
                        }
                    },
                    textColor: textColor,
                    axis: {
                        legend: {
                            text: {
                                fill: textColor
                            }
                        },
                        ticks: {
                            text: {
                                fill: textColor
                            }
                        }
                    }
                }}
                defs={gradientDefs}
                fill={keys.filter(key => key !== 'total').map((category, index) => ({
                    match: { id: category },
                    id: `gradient${index + 1}`,
                }))}
            />
        </div>
    );
};

export default DashboardYearlyChart;
