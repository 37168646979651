import React from 'react';
import { Box } from '@mui/material';
import { useGetLogicsQuery } from "../../services/api";
import useSelectedCompany from '../../hooks/useSelectedCompany';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import AllLogicsHeader from './AllLogicsHeader';
import AllLogicsTable from './AllLogicsTable';
import { useMediaQuery } from 'react-responsive';

const AllLogics = () => {
    const { companyOptions } = useCompanyOptions();
    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const { data: logicsData = [] } = useGetLogicsQuery(selectedCompany || "");
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <AllLogicsHeader 
                selectedCompany={selectedCompany}
                handleCompanyChange={handleCompanyChange}
                companyOptions={companyOptions}
                isSmallScreen={isSmallScreen}
            />
            <Box marginTop="1.5rem">
                <AllLogicsTable logicsData={logicsData} selectedCompany={selectedCompany} isSmallScreen={isSmallScreen} />
            </Box>
        </Box>
    );
}

export default AllLogics;
