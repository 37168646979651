import React from 'react';
import { Box, Typography } from '@mui/material';
import DeviceBox from './DeviceBox';

const DeviceList = ({ filteredDevices = [], theme }) => (
  <>
    {filteredDevices.length === 0 ? (
      <Box
        marginTop="40px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="55vh"
      >
        <Typography variant="h1" sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>
          NO DEVICES ADDED TO THE DASHBOARD
        </Typography>
      </Box>
    ) : (
      <Box
        marginTop="20px"
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(150px, 1fr))"
        gridAutoRows="350px"
        gap="10px"
        justifyItems="center"
        alignItems="start"
      >
        {filteredDevices.map(device => (
          <DeviceBox
            key={device._id}
            title={device.name}
            deviceType={`Type: ${device.type}`}
            macAddress={`MAC: ${device.mac}`}
            firmware={`Firmware: ${device.firmware}`}
            ip={`IP: ${device.ip}`}
            location={device.site}
            status={`Status: ${device.status}`}
          />
        ))}
      </Box>
    )}
  </>
);

export default DeviceList;
