import React from 'react';
import { Box, useTheme } from '@mui/material';
import DevicesHeader from './DowntimeLogHeader';
import { useMediaQuery } from 'react-responsive';
import SensorSelect from '../../components/SensorSelect';
import CompanySelector from '../../components/CompanySelector';
import useSensorOptions from '../../hooks/useSensorOptions';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import useSelectedSensor from '../../hooks/useSelectedSensor';
import { useSelector } from 'react-redux';
import DowntimeLogLabel from './DowntimeLogLabel';
import { useDowntimeQuery } from '../../services/api';
import DowntimeTable from './DowntimeTable';

const DeviceStatusLog = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    const { company, role } = useSelector((state) => state.global);
    const { companyOptions } = useCompanyOptions();
    const isSuperOrPowerAdmin = role === 'Super Admin' || role === 'Power Admin';

    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const sensorOptions = useSensorOptions(isSuperOrPowerAdmin ? selectedCompany : company);
    const { selectedSensor, handleSensorChange, getSelectedSensorLabel } = useSelectedSensor(sensorOptions, isSuperOrPowerAdmin ? selectedCompany : company);

    // Fetch downtime data from API
    const { data: downtimeData = [] } = useDowntimeQuery(selectedSensor);

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            {isSmallScreen ? (
                <>
                    <DevicesHeader theme={theme} />
                    <Box
                        display="flex"
                        flexDirection={isSmallScreen ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems="center"
                        gap="1rem"
                        marginBottom="1rem"
                        padding="1rem"
                        backgroundColor={theme.palette.background.paper}
                        borderRadius="15px"
                    >
                        {isSuperOrPowerAdmin && (
                            <CompanySelector selectedCompany={selectedCompany} handleCompanyChange={handleCompanyChange} companyOptions={companyOptions} />
                        )}
                        <SensorSelect 
                            sensorOptions={sensorOptions} 
                            selectedSensor={selectedSensor} 
                            handleSensorChange={handleSensorChange} 
                            isSmallScreen={isSmallScreen} 
                        />
                    </Box>
                </>
            ) : (
                <DevicesHeader theme={theme}>
                    {isSuperOrPowerAdmin && (
                        <CompanySelector selectedCompany={selectedCompany} handleCompanyChange={handleCompanyChange} companyOptions={companyOptions} />
                    )}
                    <SensorSelect 
                        sensorOptions={sensorOptions} 
                        selectedSensor={selectedSensor} 
                        handleSensorChange={handleSensorChange} 
                        isSmallScreen={isSmallScreen} 
                    />
                </DevicesHeader>    
            )}
            <DowntimeLogLabel
                sensorLabel={getSelectedSensorLabel()}
                isSmallScreen={isSmallScreen}
            />
            <DowntimeTable downtimeData={downtimeData} />
        </Box>
    );
};

export default DeviceStatusLog;
