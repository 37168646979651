import React from 'react';
import { Box } from '@mui/material';
import WeeklyBarChart from '../../charts/barCharts/WeeklyChart';
import WeeklyLineChart from '../../charts/lineCharts/WeeklyChart';
import WeeklyPieChart from '../../charts/pieCharts/WeeklyChart';
import Loading from '../../components/Loading';

const WeeklyContent = ({ selectedSensor, showLabels, devicesData, processedData, selectedMonth, selectedYear, error, isLoading, isSmallScreen, tabValue }) => {

    if (!selectedSensor) {
        return <Loading />;
    }

    return (
        <>
            <Box height="auto" width={isSmallScreen ? "auto" : "100%"}>
                {devicesData && selectedMonth && selectedYear ? (
                    <>
                        <WeeklyBarChart 
                            selectedMac={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                            showLabels={showLabels}
                            isSmallScreen={isSmallScreen}
                            processedData={processedData}
                            isLoading={isLoading}
                            error={error}
                            tabValue={tabValue}
                        />
                        <WeeklyLineChart 
                            selectedMac={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                            processedData={processedData}
                            isLoading={isLoading}
                            isSmallScreen={isSmallScreen}
                            showLabels={showLabels}
                            error={error}
                            tabValue={tabValue}
                        />
                        <WeeklyPieChart
                            selectedMac={selectedSensor}
                            selectedMonth={selectedMonth}
                            selectedYear={selectedYear}
                            processedData={processedData}
                            isLoading={isLoading}
                            isSmallScreen={isSmallScreen}
                            showLabels={showLabels}
                            tabValue={tabValue}
                        />
                    </>
                ) : (
                    <Loading />
                )}
            </Box>
        </>
    );
};

export default WeeklyContent;
