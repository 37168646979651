import React, { useState } from 'react';
import { Box } from '@mui/material';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import LicensesHeader from './LicensesHeader';
import { useGetLicensesQuery, useGetPackagesQuery, useAddPackageMutation } from '../../services/api';
import LicenseTable from './LicensesTable';
import AddPackageDialogBox from './AddPackageDialogBox';
import { useMediaQuery } from 'react-responsive';

const Licenses = () => {
    const { companyOptions } = useCompanyOptions();
    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const { data: licencesData, refetch: refetchLicensesData } = useGetLicensesQuery(selectedCompany);
    const { data: packagesData, refetch: refetchPackageData } = useGetPackagesQuery();
    const [addPackage] = useAddPackageMutation();
    const [packageData, setPackageData] = useState({ name: '', description: '', durationInDays: '' });
    const [openPackageDialog, setOpenPackageDialog] = useState(false);

    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    const handleOpenPackageDialog = () => setOpenPackageDialog(true);
    const handleClosePackageDialog = () => setOpenPackageDialog(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPackageData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleAddPackage = async () => {
        try {
            await addPackage(packageData).unwrap();
            setOpenPackageDialog(false);
            refetchLicensesData();
            refetchPackageData();
        } catch (error) {
            console.error('Error adding package:', error);
        }
    };

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <LicensesHeader 
                selectedCompany={selectedCompany}
                handleCompanyChange={handleCompanyChange}
                companyOptions={companyOptions}
                handleOpenPackageDialog={handleOpenPackageDialog}
                isSmallScreen={isSmallScreen}
            />
            {licencesData && (
                <Box marginTop="1.5rem">
                    <LicenseTable licencesData={licencesData} packagesData={packagesData} refetchLicensesData={refetchLicensesData} isSmallScreen />
                </Box>
            )}
            <AddPackageDialogBox
                open={openPackageDialog}
                handleCloseDialog={handleClosePackageDialog}
                packageData={packageData}
                handleInputChange={handleInputChange}
                handleAddPackage={handleAddPackage}
            />
        </Box>
    );
};

export default Licenses;
