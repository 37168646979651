import React from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, useTheme } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import Loading from '../../components/Loading';
import { styled } from '@mui/system';

const StyledTableContainer = styled(TableContainer)`
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    & {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
    }
`;

const WeeklyCountTable = ({ title, isLoading, data, selectedSensor, selectedMonth, selectedYear }) => {
    const theme = useTheme();

    if (isLoading) {
        return <Loading />
    }

    if (!data || !selectedSensor || !selectedMonth || !selectedYear) {
        return <NoDataAvailableMessage />
    }

    const renderDataRows = () => {
        const rows = [];
        const weeksData = data[selectedSensor]?.[selectedYear]?.[selectedMonth] || {};
        
        let previousTotal = 0;
        for (const weekNumber in weeksData) {
            const weekData = weeksData[weekNumber];
            const currentTotal = Object.values(weekData).reduce((acc, val) => acc + val, 0);

            const comparisonIcon = previousTotal > currentTotal ? <ArrowDownward color="error" /> : <ArrowUpward color="success" />;
            previousTotal = currentTotal;

            rows.push(
                <TableRow key={`${selectedSensor}-${selectedYear}-${selectedMonth}-${weekNumber}`}>
                    <TableCell align="center">{`Week ${weekNumber}`}</TableCell>
                    {Object.keys(weekData).map((key) => (
                        <TableCell align="center" key={key}>{weekData[key]}</TableCell>
                    ))}
                    <TableCell align="center">{comparisonIcon}</TableCell>
                </TableRow>
            );
        }
        return rows;
    };

    const renderTableHeader = () => {
        const firstWeekData = Object.values(data[selectedSensor]?.[selectedYear]?.[selectedMonth] || {})[0] || {};
        return (
            <TableRow>
                <TableCell align="center">Week No</TableCell>
                {Object.keys(firstWeekData).map((key) => (
                    <TableCell align="center" key={key}>{key}</TableCell>
                ))}
                <TableCell align="center">Comparison</TableCell>
            </TableRow>
        );
    };

    return (
        <Box
            backgroundColor={theme.palette.background.paper}
            borderRadius="0.55rem"
            display="flex"
            flexDirection="column"
            position="relative"
        >
            <Typography variant="h3" sx={{ color: theme.palette.secondary.main, m: "2%", fontWeight: 'bold' }}>{title}</Typography>
            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        {renderTableHeader()}
                    </TableHead>
                    <TableBody>
                        {renderDataRows()}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </Box>
    );
};

export default WeeklyCountTable;