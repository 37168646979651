import React from 'react';
import { Box, useTheme } from '@mui/material';
import QatarMap from './QatarMap';
import { useMediaQuery } from 'react-responsive';

const MapComponent = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    return (
        <Box
            sx={{
                gridColumn: isSmallScreen ? "span 12" : "span 4",
                gridRow: "span 3",
                backgroundColor: theme.palette.background.paper,
                borderRadius: "0.55rem",
                overflow: "hidden"
            }}
        >
            <Box sx={{ height: "100%", width: "100%" }}>
                <QatarMap />
            </Box>
        </Box>
    )
}

export default MapComponent;
