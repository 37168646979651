import React from 'react';
import { Box, Typography } from '@mui/material';
import AddonBox from './AddonBox';

const AddonList = ({ addonData, theme }) => (
    <>
        {addonData.length === 0 ? (
            <Box
                marginTop="40px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="55vh"
            >
                <Typography variant="h1" sx={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                    NO DEVICES ADDED TO THE DASHBOARD
                </Typography>
            </Box>
        ) : (
            <Box
                marginTop="20px"
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(150px, 1fr))"
                gridAutoRows="350px"
                gap="10px"
                justifyItems="center"
                alignItems="start"
            >
                {addonData.map(addon => (
                    <AddonBox
                        key={addon._id}
                        _id={addon._id}
                        title={addon.device.name}
                        status={`Status: ${addon.device.status}`}
                        adultnchild={addon.adultnchild}
                        gender={addon.gender}
                        mask={addon.mask}
                    />
                ))}
            </Box>
        )}
    </>
);

export default AddonList;
