import React from 'react';
import { Box, useTheme } from '@mui/material';
import Header from '../../components/Header';

const OverviewHeader = ({ isSmallScreen, children }) => {

    const theme = useTheme();

    return (
        <Box
            display="flex"
            flexDirection={isSmallScreen ? "column" : "row"}
            justifyContent="space-between"
            marginBottom="1rem"
            padding="0.5rem"
            backgroundColor={theme.palette.background.paper}
            borderRadius='15px'
        >
            <Header title="OVERVIEW" subtitle="View your overall counts of your devices" />
            <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"} alignItems="center">
                {children}
            </Box>
        </Box>
    );
};

export default OverviewHeader;
