import React from 'react';
import { FormControl, MenuItem, Select, InputLabel } from '@mui/material';

const SensorSelect = ({ sensorOptions, selectedSensor, handleSensorChange }) => {
    return (
        <FormControl sx={{ width: '100%' }}>
            <InputLabel id="sensor-label">Change Device</InputLabel>
            <Select
                labelId="sensor-label"
                id="demo-simple-select"
                value={selectedSensor}
                label="Change Device"
                onChange={handleSensorChange}
                sx={{ width: '100%' }}
            >
                {sensorOptions.map(option => (
                    <MenuItem key={option.mac} value={option.mac}>{option.sensorName}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SensorSelect;
