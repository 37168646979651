import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { customTooltip } from '../components/customTooltip';
import { gradientDefs } from '../components/gradientDefs';

const DailyChart = ({ dailyData, dailyLoading, selectedMac, selectedDateRange, error}) => {
    const themeMode = useSelector(({ global }) => global.mode);

    if (dailyLoading || !dailyData) {
        return <Loading />;
    }
    
    // Check for 404 error
    if (error && error.status === 404) {
        return <NoDataAvailableMessage />;
    }

    const dateRange = selectedDateRange[0];

    const allDates = [];
    let currentDate = new Date(dateRange.startDate);
    while (currentDate <= dateRange.endDate) {
        allDates.push(format(currentDate, 'yyyy-MM-dd'));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    const filteredChartData = allDates.map(date => {
        const dateCounts = dailyData?.[selectedMac]?.[date];
        if (dateCounts) {
            return {
                day: format(new Date(date), "do"),
                macAddress: selectedMac,
                ...dateCounts
            };
        } else if (dailyData?.[selectedMac] && dailyData[selectedMac][allDates[0]]) {
            const zeroCounts = Object.keys(dailyData[selectedMac][allDates[0]]).reduce((acc, key) => {
                acc[key] = 0;
                return acc;
            }, {});
            return {
                day: format(new Date(date), "do"),
                macAddress: selectedMac,
                ...zeroCounts
            };
        } else {
            return null;
        }
    });

    const filteredChartDataWithoutNull = filteredChartData.filter(data => data !== null);

    if (filteredChartDataWithoutNull.length === 0) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';
    const colorRange = themeMode === 'light' ? { scheme: 'spectral' } : ['#b54478', '#7209b7', '#3a0ca3', '#4361ee', '#4cc9f0'];
    const logicNames = Object.keys(filteredChartDataWithoutNull[0]).filter(key => key !== 'day' && key !== 'macAddress');

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <ResponsiveBar
                data={filteredChartDataWithoutNull}
                keys={logicNames}
                indexBy="day"
                margin={{ top: 20, right: 10, bottom: 60, left: 30 }}
                padding={0.25}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={colorRange}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -60,
                    legend: 'Day',
                    legendPosition: 'middle',
                    legendOffset: 45,
                    tickValues: 5,
                    style: {
                        tick: { fill: textColor },
                        legend: { fill: textColor }
                    }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -40,
                    tickValues: 5,
                    style: {
                        tick: { fill: textColor },
                        legend: { fill: textColor }
                    }
                }}
                enableLabel={false}
                labelSkipWidth={1}
                labelSkipHeight={1}
                labelTextColor="black"
                legends={[]}
                tooltip={customTooltip}
                motionConfig="slow"
                theme={{
                    tooltip: {
                        container: {
                            background: '#000',
                            color: '#fff',
                            fontSize: '14px',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            padding: '10px'
                        }
                    },
                    textColor: textColor,
                    axis: {
                        legend: {
                            text: {
                                fill: textColor
                            }
                        },
                        ticks: {
                            text: {
                                fill: textColor
                            }
                        }
                    }
                }}
                defs={gradientDefs}
                fill={logicNames.map((name, index) => ({
                    match: { id: name },
                    id: `gradient${index + 1}`,
                }))}
            />
        </div>
    );
};

export default DailyChart;
