import React from 'react';
import { Box } from '@mui/material';
import Lottie from 'lottie-react';
import LoadingAnimation from '../assets/animations/LoadingAnimation.json';

const Loading = () => {

    return (
        <Box
            marginTop="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="50vh"
        >
            <Lottie animationData={LoadingAnimation}/>
        </Box>
    );
};

export default Loading;
