import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import Loading from '../../components/Loading';
import CustomTooltipPie from '../components/customTooltipPie';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { gradientDefs } from '../components/gradientDefs';
import { useTheme } from '@mui/material';

const MonthlyPieChart = ({ chartIsLoading, selectedSensor, selectedYear, processedData, error, isSmallScreen, tabValue }) => {
    const themeMode = useSelector(({ global }) => global.mode);
    const theme = useTheme();
    const [activeLegends, setActiveLegends] = useState([]);

    if (chartIsLoading) {
        return (
            <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    if (error || error?.status === 404 || !processedData || Object.keys(processedData).length === 0) {
        return <NoDataAvailableMessage />;
    }

    const selectedYearValue = selectedYear instanceof Date ? selectedYear.getFullYear() : selectedYear.$y || selectedYear;

    const isDataAvailable = processedData && Object.keys(processedData).length > 0;
    const isSensorDataAvailable = isDataAvailable && processedData[selectedSensor];
    const isYearDataAvailable = isSensorDataAvailable && processedData[selectedSensor][selectedYearValue];

    if (!isYearDataAvailable) {
        return <NoDataAvailableMessage />;
    }

    const yearData = processedData[selectedSensor][selectedYearValue];

    const monthNames = Array.from({ length: 12 }, (_, i) => {
        const month = i + 1;
        return month < 10 ? `0${month}` : `${month}`;
    });

    // Aggregate data for pie chart
    const aggregatedData = {};

    monthNames.forEach(month => {
        if (yearData.hasOwnProperty(month)) {
            Object.entries(yearData[month]).forEach(([categoryName, value]) => {
                if (!aggregatedData[categoryName]) {
                    aggregatedData[categoryName] = 0;
                }
                aggregatedData[categoryName] += value;
            });
        }
    });

    const pieData = Object.entries(aggregatedData).map(([key, value]) => ({
        id: key,
        value,
    }));

    if (pieData.length === 0) {
        return <NoDataAvailableMessage />;
    }

    const fill = pieData.map((_, index) => ({
        match: { id: pieData[index].id },
        id: `gradient${index + 1}`,
    }));

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    const handleLegendClick = (legendItem) => {
        setActiveLegends((prevActiveLegends) =>
            prevActiveLegends.includes(legendItem.id)
                ? prevActiveLegends.filter((item) => item !== legendItem.id)
                : [...prevActiveLegends, legendItem.id]
        );
    };

    const filteredPieDataToDisplay = pieData.filter((item) => !activeLegends.includes(item.id));

    return (
        <div style={{
            position: 'relative',
            height: isSmallScreen ? 375 : 600,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '15px',
            marginTop: '1rem',
        }}>
            <ResponsivePie
                data={filteredPieDataToDisplay}
                margin={isSmallScreen ? { top: -50, left: 65, right: 65, bottom: 0 } : { top: 75, left: 0, right: 0, bottom: 100 }}
                startAngle={-150}
                innerRadius={0.5}
                activeOuterRadiusOffset={8}
                arcLinkLabelsDiagonalLength={9}
                arcLinkLabelsTextColor={textColor}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={textColor}
                arcLinkLabel="value"
                enableArcLabels={false}
                arcLabelsTextColor={{ from: textColor, modifiers: [['darker', 3]] }}
                defs={gradientDefs}
                fill={fill}
                tooltip={({ datum }) => <CustomTooltipPie datum={datum} />}
                legends={ isSmallScreen ? [
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: tabValue === 1 ? 0 : 20,
                        translateY: -25,
                        itemsSpacing: tabValue === 1 ? 10 : 2,
                        itemWidth: tabValue === 1 ? 80 : 75,
                        itemHeight: 10,
                        itemTextColor: textColor,
                        itemDirection: 'left-to-right',
                        symbolSize: 12,
                        symbolShape: 'square',
                        itemOpacity: 0.85,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ],
                        data: pieData.map((d, index) => ({
                            id: d.id,
                            label: d.id,
                            color: `url(#gradient${index + 1})`,
                        })),
                        onClick: handleLegendClick,
                    }
                ] : [
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 85,
                        itemsSpacing: 50,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ],
                        data: pieData.map((d, index) => ({
                            id: d.id,
                            label: d.id,
                            color: `url(#gradient${index + 1})`,
                        })),
                        onClick: handleLegendClick,
                    }
                ]}
            />
        </div>
    );
};

export default MonthlyPieChart;
