import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const DowntimeLogLabel = ({ sensorLabel, isSmallScreen }) => {
    const theme = useTheme();

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb='1rem'
            p={isSmallScreen ? '0.5rem' : '0.5rem'}
            backgroundColor={theme.palette.background.paper}
            borderRadius="15px"
        >
            <Typography
                variant={isSmallScreen ? "h3" : "h2"}
                component="div"
                sx={{
                    color: theme.palette.secondary.main,
                    fontWeight: "bold",
                    textAlign: "left",
                    ml: isSmallScreen ? '0.5rem' : '0',
                }}
            >
                {sensorLabel}
            </Typography>
        </Box>
    );
};

export default DowntimeLogLabel;