const mainLocationsInQatar = [
    { name: 'Abu Dhalouf' },
    { name: 'Abu Hamour' },
    { name: 'Al Rayyan' },
    { name: 'Ain Khaled' },
    { name: 'Ain Sinan' },
    { name: 'Al Aziziya' },
    { name: 'Baaya' },
    { name: 'Bani Hajer' },
    { name: 'Barahat Al Jufairi' },
    { name: 'Bu Fasseela' },
    { name: 'Bu Samra' },
    { name: 'Bu Sidra' },
    { name: 'Al Bidda' },
    { name: 'Dahl Al Hamam' },
    { name: 'Doha International Airport' },
    { name: 'Doha Port' },
    { name: 'Duhail' },
    { name: 'Dukhan' },
    { name: 'Al Daayen' },
    { name: 'Al Dafna' },
    { name: 'Ad Dawhah al Jadidah' },
    { name: 'Al Ebb' },
    { name: 'Al Egla' },
    { name: 'Fuwayrit' },
    { name: 'Fereej Abdel Aziz' },
    { name: 'Fereej Bin Durham' },
    { name: 'Fereej Bin Mahmoud' },
    { name: 'Fereej Bin Omran' },
    { name: 'Fereej Kulaib' },
    { name: 'Fereej Mohammed Bin Jassim' },
    { name: 'Fereej Al Amir' },
    { name: 'Fereej Al Asiri' },
    { name: 'Fereej Al Asmakh' },
    { name: 'Fereej Al Murra' },
    { name: 'Fereej Al Manaseer' },
    { name: 'Fereej Al Nasr' },
    { name: 'Fereej Al Soudan' },
    { name: 'Fereej Al Zaeem' },
    { name: 'Gharrafat Al Rayyan' },
    { name: 'Al Gharrafa' },
    { name: 'Al Ghuwariyah' },
    { name: 'Hamad Medical City' },
    { name: 'Hazm Al Markhiya' },
    { name: 'Al Hilal' },
    { name: 'Industrial Area' },
    { name: 'Izghawa (Al Rayyan)' },
    { name: 'Izghawa (Umm Salal)' },
    { name: 'Jabal Thuaileb' },
    { name: 'Jelaiah' },
    { name: 'Jeryan Jenaihat' },
    { name: 'Jeryan Nejaima' },
    { name: 'Al Jasrah' },
    { name: 'Al Jeryan' },
    { name: 'Khawr al Udayd' },
    { name: 'Al Karaana' },
    { name: 'Al Kharrara' },
    { name: 'Al Kharaitiyat' },
    { name: 'Al Kharayej' },
    { name: 'Al Kheesa' },
    { name: 'Al Khor' },
    { name: 'Al Khulaifat' },
    { name: 'Leabaib' },
    { name: 'Lebday' },
    { name: 'Lejbailat' },
    { name: 'Lekhwair' },
    { name: 'Leqtaifiya (West Bay Lagoon)' },
    { name: 'Lijmiliya' },
    { name: 'Luaib' },
    { name: 'Lusail' },
    { name: 'Al Luqta' },
    { name: 'Madinat ash Shamal' },
    { name: 'Madinat Al Kaaban' },
    { name: 'Madinat Khalifa North' },
    { name: 'Madinat Khalifa South' },
    { name: 'Mebaireek' },
    { name: 'Mehairja' },
    { name: 'Mesaieed' },
    { name: 'Mesaieed Industrial Area' },
    { name: 'Mesaimeer' },
    { name: 'Al Messila' },
    { name: 'Muaither' },
    { name: 'Muraikh' },
    { name: 'Mushayrib' },
    { name: 'Al Mamoura' },
    { name: 'Al Mansoura' },
    { name: 'Al Markhiyah' },
    { name: 'Al Mashaf' },
    { name: 'Al Masrouhiya' },
    { name: 'Al Mearad' },
    { name: 'Al Mirqab' },
    { name: 'Najma' },
    { name: 'New Al Hitmi' },
    { name: 'New Al Mirqab' },
    { name: 'New Al Rayyan' },
    { name: 'New Salata' },
    { name: 'New Fereej Al Ghanim' },
    { name: 'New Fereej Al Khulaifat' },
    { name: 'Nu`ayjah' },
    { name: 'Al Najada' },
    { name: 'Al Nasraniya' },
    { name: 'Old Airport' },
    { name: 'Old Al Ghanim' },
    { name: 'Old Al Hitmi' },
    { name: 'Old Al Rayyan' },
    { name: 'Onaiza' },
    { name: 'The Pearl' },
    { name: 'Al Qassar' },
    { name: 'Ras Abu Aboud' },
    { name: 'Ras Lafan' },
    { name: 'Rawdat Al Hamama' },
    { name: 'Rawdat Al Khail' },
    { name: 'Rawdat Egdaim' },
    { name: 'Rawdat Rashed' },
    { name: 'Rumeilah' },
    { name: 'Ar Ru\'ays' },
    { name: 'Al Rufaa' },
    { name: 'Sawda Natheel' },
    { name: 'Shagra' },
    { name: 'Simaisma' },
    { name: 'Al Sadd' },
    { name: 'As Salatah' },
    { name: 'Al Sailiya' },
    { name: 'Al Sakhama' },
    { name: 'Al Shagub' },
    { name: 'Al-Shahaniya' },
    { name: 'Al Souq' },
    { name: 'Al Seej' },
    { name: 'Al Tarfa' },
    { name: 'Al Thakhira' },
    { name: 'Al Themaid' },
    { name: 'Al Thumama (Doha)' },
    { name: 'Al Thumama (Al Wakrah)' },
    { name: 'Umm Bab' },
    { name: 'Umm Birka' },
    { name: 'Umm Ghuwailina' },
    { name: 'Umm Lekhba' },
    { name: 'Umm Qarn' },
    { name: 'Umm Salal Ali' },
    { name: 'Umm Salal Mohammed' },
    { name: 'Al Utouriya' },
    { name: 'Wadi Al Banat' },
    { name: 'Wadi Al Sail' },
    { name: 'Wadi Al Wasaah' },
    { name: 'Wadi Lusail' },
    { name: 'Al Waab' },
    { name: 'Al Wajba' },
    { name: 'Al Wakrah' },
    { name: 'Al Wukair' },
    { name: 'Al Zubarah' }
];
  
export default mainLocationsInQatar;