import React from 'react';
import { FormControl, MenuItem, Select, InputLabel } from '@mui/material';

const CompanySelector = ({ selectedCompany, handleCompanyChange, companyOptions }) => {
    return (
        <FormControl sx={{ width: '100%' }}>
            <InputLabel id="company-label">Change Company</InputLabel>
            <Select
                labelId="company-label"
                id="demo-simple-select"
                value={selectedCompany}
                label="Change Company"
                onChange={handleCompanyChange}
                sx={{ width: '100%' }}
            >
                {companyOptions.map(option => (
                    <MenuItem key={option.id} value={option.name}>{option.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CompanySelector;
