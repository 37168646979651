import React from 'react';
import AdminStatBox from '../../components/StatBox';

const AdminStatBoxes = ({ counts }) => {

    return (
        <>
            <AdminStatBox
                title="COMPANIES"
                value={counts ? counts.numberOfCompanies : 0}
            />
            <AdminStatBox
                title="DEVICES"
                value={counts ? counts.numberOfDevices : 0}
            />
            <AdminStatBox
                title="SITES"
                value={counts ? counts.numberOfSites : 0}
            />
            <AdminStatBox
                title="LOGICS"
                value={counts ? counts.numberOfLogicDocuments : 0}
            />
        </>
    )
}

export default AdminStatBoxes