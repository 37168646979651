import React from 'react';
import { Box } from '@mui/material';
import OverviewChart from '../../charts/barCharts/OverviewChart';
import Loading from '../../components/Loading';

const OverviewContent = ({ selectedSensor, overviewData, overviewLoading, error, isSmallScreen, tabValue }) => {

    return (
        <>
            <Box height={isSmallScreen ? "auto" : "65vh"}>
                {selectedSensor ? (
                    <OverviewChart
                        selectedMac={selectedSensor}
                        overviewData={overviewData}
                        overviewLoading={overviewLoading}
                        error={error}
                        isSmallScreen={isSmallScreen}
                        tabValue={tabValue}
                    />
                ) : (
                    <Loading />
                )}
            </Box>
        </>
    );
};

export default OverviewContent;
