import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import Loading from '../../components/Loading';
import { useSelector } from 'react-redux';
import { linearGradientDef } from '@nivo/core';

const DashboardOverviewChart = ({ overviewData, overviewLoading }) => {
    const themeMode = useSelector(({ global }) => global.mode);
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!overviewLoading && overviewData) {
            const transformedData = Object.entries(overviewData)
                .sort((a, b) => a[0] - b[0])
                .map(([year, monthlyData]) => {
                    return Object.entries(monthlyData)
                        .sort((a, b) => a[0] - b[0])
                        .map(([month, counts]) => {
                            const date = new Date(`${year}-${month}-01`);
                            const formattedMonth = date.toLocaleString('default', { month: 'short' }).toUpperCase();
                            const shortYear = year.slice(-2);
                            const totalCounts = Object.values(counts).reduce((acc, count) => acc + count, 0);
                            return {
                                x: `${formattedMonth}-${shortYear}`,
                                y: totalCounts,
                            };
                        });
                }).flat();

            setChartData([{ id: 'sensors', data: transformedData }]);
            setIsLoading(false);
        }
    }, [overviewData, overviewLoading]);

    if (isLoading || chartData.length === 0) {
        return (
            <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Loading />
            </div>
        );
    }

    // Define linear gradient
    const gradient = linearGradientDef('gradient10', [
        { offset: 0, color: '#28CBFE' },
        { offset: 100, color: '#a46af7' }
    ]);

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ResponsiveLine
                data={chartData}
                xScale={{ type: 'point' }}
                defs={[gradient]}
                fill={[{ match: '*', id: 'gradient10' }]}
                role="false"
                margin={{ top: 25, right: 30, bottom: 60, left: 60 }}
                yScale={{ type: 'linear', min: 0, stacked: false, reverse: false }}
                curve="catmullRom"
                axisBottom={{
                    tickRotation: -40,
                }}
                axisLeft={{ tickSize: 5, tickPadding: 5, tickRotation: 0, tickValues: 4, }}
                isInteractive={true}
                enableGridX={false}
                enablePoints={false}
                pointSize={9}
                pointColor="black"
                useMesh={true}
                enableGridY={true}
                enableSlices={false}
                enableArea={true}
                enablePointLabel={false}
                areaBlendMode="normal"
                areaBaselineValue={100}
                areaOpacity={1}
                debugMesh={false}
                debugSlices={false}
                enableCrosshair={true}
                pointLabel="data.yFormatted"
                theme={{ textColor: textColor, axis: { legend: { text: { fill: textColor } }, ticks: { text: { fill: textColor } } } }}
                pointBorderWidth={0}
                pointBorderColor="black"
                crosshairType="bottom-left"
                lineWidth={0}
                tooltip={({ point }) => (
                    <div style={{ background: '#000000', color: '#FFFFFF', fontSize: '14px', borderRadius: '4px', padding: '15px' }}>
                        Count: {point.data.y}
                    </div>
                )}
                sliceTooltip={() => null}
                motionConfig="slow"
                legends={[]}
                colors={['#28CBFE']}
                layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
            />
        </div>
    );
}

export default DashboardOverviewChart;
