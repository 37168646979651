import React from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import Header from '../../components/Header';
import CompanySelector from '../../components/CompanySelector';

const AddonManagementHeader = ({ selectedCompany, handleCompanyChange, companyOptions, isSmallScreen }) => {
    const theme = useTheme();

    return (
        <Box display="flex" flexDirection="column" gap="1rem">
            {isSmallScreen ? (
                <>
                    <Box 
                        display="flex" 
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="0.5rem"
                    >
                        <Header title="ADDON MANAGEMENT" subtitle="View and manage all your addons" />
                    </Box>
                    <Box 
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between" 
                        width="100%" 
                        backgroundColor={theme.palette.background.paper} 
                        borderRadius='15px' 
                        padding="1rem"
                        gap="1rem"
                    >
                        <CompanySelector 
                            selectedCompany={selectedCompany}
                            handleCompanyChange={handleCompanyChange}
                            companyOptions={companyOptions}
                        />
                    </Box>
                </>
            ) : (
                <Box 
                    display="flex" 
                    justifyContent="space-between" 
                    width="100%" 
                    backgroundColor={theme.palette.background.paper} 
                    borderRadius='15px' 
                    padding="0.5rem"
                >
                    <Header title="ADDON MANAGEMENT" subtitle="View and manage all your addons" />
                    <Box display="flex" alignItems="center" gap="1rem">
                        <CompanySelector 
                            selectedCompany={selectedCompany}
                            handleCompanyChange={handleCompanyChange}
                            companyOptions={companyOptions}
                        />
                    </Box>
                </Box>
            )}
            {selectedCompany && (
                <Box
                    backgroundColor={theme.palette.background.paper}
                    borderRadius='15px'
                    padding="0.5rem"
                >
                    <Typography
                        variant={isSmallScreen ? "h3" : "h2"}
                        component="div"
                        sx={{
                            color: theme.palette.secondary.main,
                            fontWeight: "bold",
                            textAlign: "left",
                            ml: isSmallScreen ? '0.5rem' : '0',
                        }}
                    >
                        {selectedCompany}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default AddonManagementHeader;
