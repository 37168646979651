import React, { useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import Loading from '../../components/Loading';
import NoDataAvailableMessage from '../../components/NoDataAvailable';
import { useSelector } from 'react-redux';
import { getISOWeek } from 'date-fns';
import { customTooltip } from '../components/customTooltip';
import { gradientDefs } from '../components/gradientDefs';

const WeeklyChart = ({ weeklyData, weeklyLoading, selectedMac, selectedMonth, selectedYear, error }) => {
    const themeMode = useSelector(({ global }) => global.mode);

    const chartData = useMemo(() => {
        if (!weeklyData || weeklyLoading) return [];

        const startDate = new Date(selectedYear, selectedMonth - 1, 1);
        const endDate = new Date(selectedYear, selectedMonth, 0);
        const startISOWeek = getISOWeek(startDate);
        const endISOWeek = getISOWeek(endDate);

        const yearData = weeklyData[selectedMac]?.[selectedYear];
        if (!yearData) return [];

        const chartData = [];
        let currentWeek = startISOWeek;

        while (currentWeek <= endISOWeek) {
            const weekData = yearData[selectedMonth]?.[currentWeek];
            if (weekData) {
                const { Total, ...filteredWeekData } = weekData;
                chartData.push({ week: currentWeek, ...filteredWeekData });
            }
            currentWeek++;
        }

        return chartData;
    }, [weeklyData, weeklyLoading, selectedMac, selectedYear, selectedMonth]);

    if (weeklyLoading) {
        return <Loading />;
    }
    
    // Check for 404 error
    if (error || error?.status === 404 || !chartData.length || !weeklyData) {
        return <NoDataAvailableMessage />;
    }

    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <ResponsiveBar
                data={chartData}
                keys={Object.keys(chartData[0]).filter(key => key !== 'week' && key !== 'Total')}
                indexBy="week"
                margin={{ top: 20, right: 10, bottom: 50, left: 30 }}
                padding={0.5}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Week',
                    legendPosition: 'middle',
                    legendOffset: 45,
                    tickValues: 5,
                    style: { tick: { fill: '#ffffff' }, legend: { fill: '#ffffff' } }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -45,
                    tickValues: 5,
                    style: { tick: { fill: '#ffffff' }, legend: { fill: '#ffffff' } }
                }}
                enableLabel={false}
                labelSkipWidth={1}
                labelSkipHeight={1}
                labelTextColor="black"
                legends={[]}
                tooltip={customTooltip}
                motionConfig="slow"
                theme={{
                    tooltip: {
                        container: {
                            background: '#000',
                            color: '#fff',
                            fontSize: '14px',
                            borderRadius: '4px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
                            padding: '10px'
                        }
                    },
                    textColor: textColor,
                    axis: {
                        legend: {
                            text: {
                                fill: textColor
                            }
                        },
                        ticks: {
                            text: {
                                fill: textColor
                            }
                        }
                    }
                }}
                defs={gradientDefs}
                fill={Object.keys(chartData[0]).filter(key => key !== 'week' && key !== 'Total').map((name, index) => ({
                    match: { id: name },
                    id: `gradient${index + 1}`,
                }))}
            />
        </div>
    );
};

export default WeeklyChart;
