import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useTotalDataQuery } from "../../services/api";
import DashboardHeader from './DashboardHeader';
import StatBoxes from './StatBoxes';
import OverviewChart from './OverviewChart';
import YearlyChart from './YearlyChart';
import TotalCountPie from './TotalCountPie';
import MapComponent from './MapComponent';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

const Dashboard = () => {
    const [totalDevicesConnected, setTotalDevicesConnected] = useState(0);
    const [totalSites, setTotalSites] = useState(0);
    const [totalHumanCount, setTotalHumanCount] = useState(0);
    const [devicesOffline, setDevicesOffline] = useState(0);
    const [totalData, setTotalData] = useState({});
    const [yearlyData, setYearlyData] = useState({});
    const [monthlyData, setMonthlyData] = useState({});

    const company = useSelector(state => state.global.company);
    const { data: totalCountData, isLoading } = useTotalDataQuery(company);

    useEffect(() => {
        if (totalCountData) {
            setTotalDevicesConnected(totalCountData.deviceCount);
            setTotalSites(totalCountData.siteCount);
            setDevicesOffline(totalCountData.offlineDeviceCount);
            setTotalData(totalCountData.totalData);
            setYearlyData(totalCountData.yearlyData);
            setMonthlyData(totalCountData.monthlyData);
            const totalHumanCount = Object.values(totalCountData.totalData).reduce((acc, curr) => acc + curr, 0);
            setTotalHumanCount(totalHumanCount);
        }
    }, [totalCountData]);

    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <DashboardHeader />
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 2fr)"
                gridAutoRows="160px"
                gap="20px"
            >
                <StatBoxes 
                    totalDevicesConnected={totalDevicesConnected} 
                    totalSites={totalSites} 
                    devicesOffline={devicesOffline} 
                    totalHumanCount={totalHumanCount} 
                />
                <OverviewChart data={monthlyData} isLoading={isLoading} />
                <MapComponent />
                <TotalCountPie data={totalData} isLoading={isLoading} />
                <YearlyChart data={yearlyData} isLoading={isLoading} />
            </Box>
        </Box>
    );
};

export default Dashboard;
