import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, useTheme, Button } from '@mui/material';
import 'ol/ol.css';
import { useGeographic } from 'ol/proj';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const SiteBox = ({ title, location, devicesConnected, latitude, longitude, siteId, enabled, onStatusChange, onRemove, isSmallScreen }) => {
  const theme = useTheme();
  useGeographic();
  const mapContainerRef = useRef(null);
  const [status, setStatus] = useState(enabled ? 'enabled' : 'disabled');
  const { role } = useSelector((state) => state.global);
  const isAdmin = role === 'Admin';

  const handleStatusChange = (event, newStatus) => {
    setStatus(newStatus);
    onStatusChange(siteId, newStatus);
  };

  useEffect(() => {
    const uniqueId = `map-${Math.random().toString(36).substring(2)}`;
    mapContainerRef.current.id = uniqueId;

    const map = new Map({
      target: uniqueId,
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      view: new View({
        center: [longitude, latitude],
        zoom: 18
      })
    });

    const marker = new Feature({
      geometry: new Point([longitude, latitude])
    });

    marker.setStyle(new Style({
      image: new Icon({
        src: 'https://img.icons8.com/color/48/000000/marker.png',
        anchor: [0.5, 1],
        scale: 0.75
      })
    }));

    const vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: [marker]
      })
    });

    map.addLayer(vectorLayer);

    return () => {
      map.setTarget(null);
    };
  }, [latitude, longitude]);

  const handleDeleteClick = () => {
    onRemove();
  };

  return (
    <Box
      position="relative"
      backgroundColor={theme.palette.background.paper}
      p="1rem"
      borderRadius="0.55rem"
      mb="20px"
      sx={{ overflow: 'hidden' }}
    >
      <Box
        ref={mapContainerRef}
        style={{ width: '100%', height: '300px', marginBottom: '20px' }}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px="10px"
      >
        <Typography variant={isSmallScreen ? "h3" : "h2"} color={theme.palette.secondary.main} fontWeight="bold" mb="15px">
          {title}
        </Typography>
        {isAdmin && (
          <ToggleButtonGroup
            value={status}
            exclusive
            onChange={handleStatusChange}
            size="small"
          >
            <ToggleButton
              value="enabled"
              sx={{
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              Enabled
            </ToggleButton>
            <ToggleButton
              value="disabled"
              sx={{ '&:focus': { outline: 'none' } }}
            >
              Disabled
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>
      {isAdmin && (
        <Button
          onClick={handleDeleteClick}
          variant="contained"
          color="error"
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            mr: '25px',
            mb: '25px',
            '&:focus': { outline: 'none' },
          }}
        >
          Remove Site
        </Button>
      )}
      <Typography variant={isSmallScreen ? "h5" : "h4"} sx={{ mb: "10px", px: "10px" }}>
        Location: {location}
      </Typography>
      <Typography variant={isSmallScreen ? "h6" : "h5"} sx={{ mb: "10px", px: "10px" }}>
        Devices Connected: {devicesConnected}
      </Typography>
    </Box>
  );
};

export default SiteBox;
