import React from 'react';
import { Button, Popover, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { YearCalendar } from '@mui/x-date-pickers/YearCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const YearPopover = ({ anchorEl, handlePopoverOpen, handlePopoverClose, selectedYearRange, setSelectedYearRange, isSmallScreen }) => {
    const theme = useTheme();

    return (
        <>
            <Button
                variant="outlined"
                size="large"
                style={{
                    color: theme.palette.text.white,
                    borderColor: '#bbbdbd',
                    width: '100%',
                    marginBottom: isSmallScreen ? '0.5rem' : '0'
                }}
                onClick={handlePopoverOpen}
            >
                Select Year
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <YearCalendar
                        date={selectedYearRange}
                        onChange={(newYear) => {
                            setSelectedYearRange([newYear]);
                            handlePopoverClose();
                        }}
                    />
                </LocalizationProvider>
            </Popover>
        </>
    );
};

export default YearPopover;
