// src/components/QatarMap.js
import React, { useRef, useEffect } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';

const QatarMap = () => {
    const mapRef = useRef();

    useEffect(() => {
        const map = new Map({
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                center: fromLonLat([51.183307, 25.249323]),
                zoom: 8,
            }),
        });

        return () => map.setTarget(undefined);
    }, []);

    return <div ref={mapRef} style={{ height: '100%', width: '100%' }} />;
};

export default QatarMap;
