import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useGetAllUsersQuery, useGetPermissionsQuery, useAddUserMutation, useSendInvitationMutation } from '../../services/api';
import AdminManagementHeader from './AdminManagementHeader';
import AdminManagementTable from './AdminManagementTable';
import AddUserDialogBox from './AddUserDialogBox';
import ErrorSnackbar from '../../components/ErrorSnackbar';
import { useMediaQuery } from 'react-responsive';

const AdminManagement = () => {
    const { data: usersData, refetch: refetchUsers } = useGetAllUsersQuery(' ');
    const { data: permissionsData } = useGetPermissionsQuery();
    const [addUserMutation] = useAddUserMutation();
    const [sendInvitation] = useSendInvitationMutation();
    const { company } = useSelector((state) => state.global);
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    const [userData, setUserData] = useState({ name: '', email: '', country: '', phoneNumber: '', role: '' });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
    const [openDialog, setOpenDialog] = useState(false);
    
    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    const handleAddUser = async () => {
        try {
            if (!userData.name || !userData.email || !userData.role) {
                showSnackbar("Please fill all fields", 'error');
            } else {
                const addUserResponse = await addUserMutation({
                    name: userData.name,
                    email: userData.email,
                    role: userData.role,
                    company: company,
                });

                if (addUserResponse.error) {
                    showSnackbar("User already exists", 'error');
                } else {
                    await sendInvitation({
                        name: userData.name,
                        email: userData.email,
                    });

                    showSnackbar('User added successfully', 'success');
                    refetchUsers();
                }
            }
        } catch (error) {
            console.error('Error adding user:', error);
            showSnackbar('Failed to add user', 'error');
        } finally {
            handleCloseDialog();
        }
    };

    const showSnackbar = (message, severity) => {
        setSnackbar({ open: true, message, severity });
    };

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <AdminManagementHeader handleOpenDialog={handleOpenDialog} isSmallScreen={isSmallScreen} />
            {usersData && permissionsData && (
                <Box marginTop="1.5rem">
                    <AdminManagementTable 
                        userData={usersData} 
                        permissionsData={permissionsData} 
                        refetch={refetchUsers}
                        showSnackbar={showSnackbar}
                        isSmallScreen={isSmallScreen}
                    />
                </Box>
            )}
            <AddUserDialogBox
                open={openDialog}
                handleCloseDialog={handleCloseDialog}
                userData={userData}
                handleInputChange={handleInputChange}
                handleAddUser={handleAddUser}
            />
            <ErrorSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            />
        </Box>
    );
}

export default AdminManagement;
