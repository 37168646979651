import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';
import { useGetDevicesQuery, useAddDeviceMutation, useAddDeviceToSiteMutation, useGetSitesQuery } from "../../services/api";
import useSelectedCompany from '../../hooks/useSelectedCompany';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import AllDevicesHeader from './AllDevicesHeader';
import AllDevicesTable from './AllDevicesTable';
import AddDeviceDialogBox from './AddDeviceDialogBox';
import ErrorSnackbar from '../../components/ErrorSnackbar';
import { useMediaQuery } from 'react-responsive';

const AllDevices = () => {
    const theme = useTheme();
    const { company } = useSelector((state) => state.global);
    const { companyOptions } = useCompanyOptions();
    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const { data: devicesData, refetch } = useGetDevicesQuery(selectedCompany);
    const { data: sitesData } = useGetSitesQuery(selectedCompany);
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });
    
    const [openDialog, setOpenDialog] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
    const [addDevice] = useAddDeviceMutation();
    const [addDeviceToSite] = useAddDeviceToSiteMutation();

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);
    const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

    const handleAddDevice = async (ipAddress, macAddress, site) => {
        if (!ipAddress || !macAddress || !site) {
            setSnackbar({
                open: true,
                message: "Please fill all fields and select a site.",
                severity: "error"
            });
            return;
        }

        try {
            const response = await addDevice({ ipAddress, macAddress, site });
            if (response.error) {
                setSnackbar({
                    open: true,
                    message: "ERROR: No matching device for your company.",
                    severity: "error"
                });
            } else {
                await addDeviceToSite({ company, name: site, macAddress });
                setSnackbar({
                    open: true,
                    message: "Device added successfully!",
                    severity: "success"
                });
                handleCloseDialog();
                refetch();
            }
        } catch (error) {
            console.error('Error adding device:', error);
        }
    };

    const filteredSites = useMemo(() => {
        return sitesData ? sitesData.filter(site => site.company === company && site.enabled) : [];
    }, [sitesData, company]);

    const filteredDevices = useMemo(() => {
        return devicesData && devicesData.filter(device => device.addedToDashboard);
    }, [devicesData]);

    return (
        <Box m={isSmallScreen ? "1rem" : "1rem 2.5rem"}>
            <AllDevicesHeader 
                selectedCompany={selectedCompany}
                handleCompanyChange={handleCompanyChange}
                companyOptions={companyOptions}
                handleOpenDialog={handleOpenDialog}
                isSmallScreen={isSmallScreen}
            />
            <Box marginTop="1.5rem">
                <AllDevicesTable devicesData={filteredDevices} selectedCompany={selectedCompany} isSmallScreen={isSmallScreen} />
            </Box>
            <AddDeviceDialogBox
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                handleAddDevice={handleAddDevice}
                filteredSites={filteredSites}
                theme={theme}
            />
            <ErrorSnackbar
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </Box>
    );
}

export default AllDevices;
