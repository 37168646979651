import React from 'react';
import { Popover, Button, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DatePopover = ({ selectedDate, handleDateChange, handlePopoverOpen, handlePopoverClose, anchorEl, isSmallScreen }) => {
    
    const theme = useTheme();
    
    return (
        <>
            <Button
                variant="outlined"
                size="large"
                style={{
                    color: theme.palette.text.white,
                    borderColor: '#bbbdbd',
                    width: '100%',
                    marginBottom: isSmallScreen ? '0.5rem' : '0'
                }}
                onClick={handlePopoverOpen}
            >
                Select Date
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{
                    sx: {
                        marginTop: '20px',
                        width: isSmallScreen ? '80%' : '300px', // Responsive width for the popover
                        maxWidth: '100%', // Ensure the popover doesn't overflow the screen width
                    },
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        views={['year', 'month', 'day']}
                        value={selectedDate}
                        onChange={handleDateChange}
                        sx={{ paddingTop: '10px', width: '100%' }} // Make the calendar take the full width
                    />
                </LocalizationProvider>
            </Popover>
        </>
    );
};

export default DatePopover;
