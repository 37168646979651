import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSignupMutation } from "../../services/api";
import backgroundIMG from "../../assets/background.jpg";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import logo from "../../assets/logo-dark.png";

const Signup = () => {
    const [inputs, setInputs] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        country: "",
        phoneNumber: "",
    });

    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [signup] = useSignupMutation();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs({ ...inputs, [name]: value });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (inputs.password !== inputs.confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            const { confirmPassword, ...userData } = inputs;
            const response = await signup(userData);

            if (response.error) {
                setError(response.error.message);
                setSnackbarMessage(response.error.message);
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                return;
            }

            setSnackbarMessage("Account activated successfully");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setTimeout(() => {
                navigate("/login");
            }, 2000);
        } catch (error) {
            setError("Failed to activate account");
            setSnackbarMessage("Failed to activate account");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    return (
        <Box
            style={{
                backgroundImage: `url(${backgroundIMG})`,
                backgroundSize: 'cover',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* Logo Box */}
                <Box
                    style={{
                        marginBottom: '20px',
                        textAlign: 'center',
                    }}
                >
                    <img src={logo} alt="Logo" style={{ width: '400px', height: 'auto' }} />
                </Box>

                {/* Signup Box */}
                <Box
                    sx={{
                        width: '400px',
                        display: 'flex',
                        borderRadius: '10px',
                        backdropFilter: 'blur(45px)',
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        color: '#ff5e14',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '40px',
                    }}
                >
                    <Typography variant="h1" sx={{ fontSize: '35px', mb: '40px', fontWeight: 'bold', }}>
                        CREATE ACCOUNT
                    </Typography>
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <TextField
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                            value={inputs.email}
                            required
                            fullWidth
                            sx={{
                                mb: '10px',
                                backgroundColor: '#edf5f3',
                                borderRadius: '10px',
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: 'none',
                                    },
                                    "&:hover fieldset": {
                                        border: 'none',
                                    },
                                    "&.Mui-focused fieldset": {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                        <TextField
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                            value={inputs.password}
                            required
                            fullWidth
                            sx={{
                                mb: '10px',
                                backgroundColor: '#edf5f3',
                                borderRadius: '10px',
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: 'none',
                                    },
                                    "&:hover fieldset": {
                                        border: 'none',
                                    },
                                    "&.Mui-focused fieldset": {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                        <TextField
                            type="password"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            onChange={handleChange}
                            value={inputs.confirmPassword}
                            required
                            fullWidth
                            sx={{
                                mb: '10px',
                                backgroundColor: '#edf5f3',
                                borderRadius: '10px',
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: 'none',
                                    },
                                    "&:hover fieldset": {
                                        border: 'none',
                                    },
                                    "&.Mui-focused fieldset": {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                        <TextField
                            type="text"
                            placeholder="Country"
                            name="country"
                            onChange={handleChange}
                            value={inputs.country}
                            required
                            fullWidth
                            sx={{
                                mb: '10px',
                                backgroundColor: '#edf5f3',
                                borderRadius: '10px',
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: 'none',
                                    },
                                    "&:hover fieldset": {
                                        border: 'none',
                                    },
                                    "&.Mui-focused fieldset": {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                        <TextField
                            type="tel"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            onChange={handleChange}
                            value={inputs.phoneNumber}
                            required
                            fullWidth
                            sx={{
                                mb: '10px',
                                backgroundColor: '#edf5f3',
                                borderRadius: '10px',
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: 'none',
                                    },
                                    "&:hover fieldset": {
                                        border: 'none',
                                    },
                                    "&.Mui-focused fieldset": {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                        {error && (
                            <Box
                                sx={{
                                    width: '370px',
                                    padding: '15px',
                                    margin: '5px 0',
                                    fontSize: '14px',
                                    backgroundColor: '#f34646',
                                    color: 'white',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                }}
                            >
                                {error}
                            </Box>
                        )}

                        <Box marginTop="20px">
                            <Button 
                                type="submit" 
                                variant="contained" 
                                sx={{ backgroundColor: "#ff5e14", "&:hover": { backgroundColor: "#d65f0f" } }}
                                fullWidth
                            >
                                Activate Account
                            </Button>
                        </Box>
                    </form>
                </Box>

                {/* Copyrights Box */}
                <Box
                    style={{
                        marginTop: '20px',
                        textAlign: 'center',
                        borderRadius: '10px'
                    }}
                >
                    <Typography variant="h6" style={{ margin: '10px', textAlign: 'center', color: 'white' }}>
                        © 2024 JTRACK. All Rights Reserved
                    </Typography>
                </Box>
            </Box>
            <ErrorSnackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </Box>
    );
};

export default Signup;
