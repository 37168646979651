import React, { useState, useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetSitesQuery, useGetAlternativesQuery, useUpdateAlternativeMutation, useUpdateSiteAlternativeMutation } from "../../services/api";
import ErrorSnackbar from '../../components/ErrorSnackbar';
import EditDataHeader from './EditDataHeader';
import SiteDetailsForm from './SiteDetailsForm';
import DeviceDetailsForm from './DeviceDetailsForm';
import { useMediaQuery } from 'react-responsive';

const EditData = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    const { company } = useSelector((state) => state.global);
    const { data: sitesData } = useGetSitesQuery(company);
    const { data: devicesData } = useGetAlternativesQuery(company);
    const [updateDeviceDetails] = useUpdateAlternativeMutation();
    const [updateSiteDetails] = useUpdateSiteAlternativeMutation();

    const [site, setSite] = useState('');
    const [device, setDevice] = useState('');

    const [siteDetails, setSiteDetails] = useState({
        name: '',
        location: '',
        latitude: '',
        longitude: ''
    });

    const [deviceDetails, setDeviceDetails] = useState({
        name: '',
        mac: '',
        entry: '',
        exit: '',
        adultEntry: '',
        childEntry: '',
        adultExit: '',
        childExit: '',
        zoneIn: '',
        zoneOut: '',
        maleEntry: '',
        femaleEntry: '',
        maleExit: '',
        femaleExit: ''
    });

    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

    const handleInputChange = (e, field) => {
        const { value } = e.target;
        if (device) {
            setDeviceDetails((prev) => ({ ...prev, [field]: value }));
        }
        if (site) {
            setSiteDetails((prev) => ({ ...prev, [field]: value }));
        }
    };

    const handleUpdateDeviceDetails = async () => {
        try {
            await updateDeviceDetails({
                variables: {
                    input: {
                        company,
                        mac: deviceDetails.mac,
                        deviceName: deviceDetails.name,
                        entryExit: deviceDetails.entry,
                        exitEntry: deviceDetails.exit,
                        adult_entryExit: deviceDetails.adultEntry,
                        child_entryExit: deviceDetails.childEntry,
                        adult_exitEntry: deviceDetails.adultExit,
                        child_exitEntry: deviceDetails.childExit,
                        zoneIn: deviceDetails.zoneIn,
                        zoneOut: deviceDetails.zoneOut,
                        male_entryExit: deviceDetails.maleEntry,
                        female_entryExit: deviceDetails.femaleEntry,
                        male_exitEntry: deviceDetails.maleExit,
                        female_exitEntry: deviceDetails.femaleExit,
                    }
                }
            });
            setSnackbar({ open: true, message: 'Device details updated successfully', severity: 'success' });
        } catch (error) {
            console.error('Error updating device details:', error);
            setSnackbar({ open: true, message: 'Failed to update device details', severity: 'error' });
        }
    };

    const handleUpdateSiteDetails = async () => {
        try {
            await updateSiteDetails({
                variables: {
                    input: {
                        company,
                        name: siteDetails.name,
                        location: siteDetails.location,
                        latitude: siteDetails.latitude,
                        longitude: siteDetails.longitude
                    }
                }
            });
            setSnackbar({ open: true, message: 'Site details updated successfully', severity: 'success' });
        } catch (error) {
            console.error('Error updating site details:', error);
            setSnackbar({ open: true, message: 'Failed to update site details', severity: 'error' });
        }
    };

    const handleSiteSelect = (e) => {
        const selectedSite = sitesData.find((site) => site.name === e.target.value);
        if (selectedSite) {
            setSite(selectedSite.name);
            setSiteDetails(selectedSite);
        }
    };

    const handleDeviceSelect = (e) => {
        const selectedDevice = filteredDevices.find(device => device.deviceName === e.target.value);
        if (selectedDevice) {
            setDevice(selectedDevice.deviceName);
            setDeviceDetails({
                name: selectedDevice.deviceName,
                mac: selectedDevice.mac,
                entry: selectedDevice.entryExit,
                exit: selectedDevice.exitEntry,
                adultEntry: selectedDevice.adult_entryExit,
                childEntry: selectedDevice.child_entryExit,
                adultExit: selectedDevice.adult_exitEntry,
                childExit: selectedDevice.child_exitEntry,
                zoneIn: selectedDevice.zoneIn,
                zoneOut: selectedDevice.zoneOut,
                maleEntry: selectedDevice.male_entryExit,
                femaleEntry: selectedDevice.female_entryExit,
                maleExit: selectedDevice.male_exitEntry,
                femaleExit: selectedDevice.female_exitEntry,
            });
        }
    };

    const filteredSites = useMemo(() => sitesData?.filter(site => site.company === company) || [], [sitesData, company]);
    const filteredDevices = useMemo(() => devicesData?.filter(device => device.company === company) || [], [devicesData, company]);

    return (
        <Box m={isSmallScreen ? "0.5rem" : "1rem 2.5rem"}>
            <EditDataHeader />
            <Box
                px={4}
                py={2}
                sx={{
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary
                }}
            >
                <SiteDetailsForm
                    site={site}
                    siteDetails={siteDetails}
                    handleInputChange={handleInputChange}
                    handleSiteSelect={handleSiteSelect}
                    handleUpdateSiteDetails={handleUpdateSiteDetails}
                    filteredSites={filteredSites}
                    isSmallScreen={isSmallScreen}
                />
                <DeviceDetailsForm
                    device={device}
                    deviceDetails={deviceDetails}
                    handleInputChange={handleInputChange}
                    handleDeviceSelect={handleDeviceSelect}
                    handleUpdateDeviceDetails={handleUpdateDeviceDetails}
                    filteredDevices={filteredDevices}
                    isSmallScreen={isSmallScreen}
                />
            </Box>
            <ErrorSnackbar open={snackbar.open} message={snackbar.message} severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })} />
        </Box>
    );
};

export default EditData;
