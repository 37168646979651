import React from 'react';
import Header from '../../components/Header';
import { Box } from '@mui/material';

const Notifications = () => {
    return (
      <Box m="1.5rem 2.5rem">
            <Header title="NOTIFICATIONS" subtitle="See your list of notifications of your devices." />
            
      </Box>
    );
}

export default Notifications