import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Lottie from 'lottie-react';
import StatBoxAnimation from '../assets/animations/StatBoxAnimation.json';

const StatBox = ({ title, value, isSmallScreen }) => {
    // Theme
    const theme = useTheme();
    
    // JSX
    return (
        <Box
            gridColumn={isSmallScreen ? "span 12" : "span 3"}
            gridRow="span 1"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            borderRadius="0.55rem"
            position="relative"
            overflow="hidden"
            backgroundColor={theme.palette.background.paper}
            sx={{ height: '150px', width: '100%' }}
        >
            <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                zIndex={0}
                sx={{ 
                    transform: 'translate(-50%, 20%)', 
                    overflow: 'hidden',
                    width: '150%',
                    height: '150%'
                }} 
            >
                <Lottie animationData={StatBoxAnimation} loop={true} style={{ width: '100%', height: '100%' }} />
            </Box>
            
            <Box
                position="relative"
                zIndex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
            >
                <Typography variant="h5" sx={{ mt: '1rem', mb: '1rem', color: theme.palette.secondary.main, fontWeight: 'bold' }}>
                    {title}
                </Typography>
                <Typography variant="h2" fontWeight="600">
                    {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Typography>
            </Box>
        </Box>
    );
};

export default StatBox;
