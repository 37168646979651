import React from 'react';
import { Button, Popover, useTheme } from '@mui/material';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DateRangePopover = ({ anchorEl, handlePopoverOpen, handlePopoverClose, selectedDateRange, setSelectedDateRange, isSmallScreen }) => {
    const theme = useTheme();

    const adjustEndDate = (date) => {
        const adjustedDate = new Date(date);
        adjustedDate.setHours(23, 59, 59, 999);
        return adjustedDate;
    };

    return (
        <>
            <Button
                variant="outlined"
                size="large"
                style={{
                    color: theme.palette.text.white,
                    borderColor: '#bbbdbd',
                    width: '100%',
                    marginBottom: isSmallScreen ? '0.5rem' : '0'
                }}
                onClick={handlePopoverOpen}
            >
                Select Date Range
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <DateRange
                    ranges={selectedDateRange}
                    onChange={(ranges) => {
                        const adjustedEndDate = adjustEndDate(ranges.selection.endDate);
                        setSelectedDateRange([{ startDate: ranges.selection.startDate, endDate: adjustedEndDate, key: 'selection' }]);
                    }}
                />
            </Popover>
        </>
    );
};

export default DateRangePopover;
