import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Slide from '@mui/material/Slide';

// Forward transition component
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddDeviceDialogBox = ({ openDialog, handleCloseDialog, handleAddDevice, filteredSites, theme }) => {
  const [ipAddress, setIpAddress] = useState('');
  const [macAddress, setMacAddress] = useState('');
  const [site, setSite] = useState('');

    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            onClose={handleCloseDialog}
            aria-describedby="add-device-dialog-description"
            PaperProps={{
                    sx: { 
                        backgroundColor: theme.palette.background.paper, 
                        maxWidth: 'auto'
                    }
                }}
        >
        <DialogTitle sx={{ color: theme.palette.secondary.main }} variant="h2" fontWeight="bold">
            Add Device
        </DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="ip"
                label="IP Address"
                fullWidth
                value={ipAddress}
                onChange={(e) => setIpAddress(e.target.value)}
            />
            <TextField
                margin="dense"
                id="mac"
                label="MAC Address"
                fullWidth
                value={macAddress}
                onChange={(e) => setMacAddress(e.target.value)}
                sx={{ marginBottom: '16px' }}
            />
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                    Select Site
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={site}
                    label="Select Site"
                    onChange={(e) => setSite(e.target.value)}
                >
                    {filteredSites.map((site) => (
                    <MenuItem key={site._id} value={site.name}>
                        {site.name}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleCloseDialog}
                variant="contained"
                sx={{
                    color: theme.palette.text.black,
                    backgroundColor: theme.palette.primary.main,
                    fontWeight: 'bold',
                    '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    },
                    '&:focus': {
                    outline: 'none',
                    },
                }}
            >
                Cancel
            </Button>
            <Button
                onClick={() => handleAddDevice(ipAddress, macAddress, site)}
                variant="contained"
                sx={{
                    color: theme.palette.text.black,
                    backgroundColor: theme.palette.primary.main,
                    fontWeight: 'bold',
                    '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    },
                    '&:focus': {
                    outline: 'none',
                    },
                }}
            >
                Add
            </Button>
        </DialogActions>
        </Dialog>
    );
};

export default AddDeviceDialogBox;
