import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        } else {
            headers.delete('Authorization');
        }
        headers.set('Content-Type', 'application/json');
        headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
        headers.set('Pragma', 'no-cache');
        headers.set('Expires', '0');
        return headers;
    },
    credentials: 'include',
});


export const api = createApi({
    baseQuery,
    reducerPath: "adminApi",
    tagTypes: [
        //general
        "Login",
        "Signup",
        "User",
        "AllUsers",
        "Refresh",
        "Logout",
        "UpdateUser",
        "AddUser",
        "DeactivateUser",
        "ActivateUser",
        "RemoveUser",
        "EditUser",
        "ChnagePassword",
        "SendInvitation",

        //devices
        "Devices",
        "DeviceNames",
        "AddDevice",
        "RemoveDevice",
        "Downtime",

        //sites
        "Sites",
        "AddSite",
        "RemoveSite",
        "AddDeviceToSite",
        "RemoveDeviceFromSite",
        "DisableSite",
        "EnableSite",

        //logics
        "Logics",

        //data
        "Data",
        "OverviewData",
        "HourlyData",
        "DailyData",
        "WeeklyData",
        "MonthlyData",
        "YearlyData",
        "TotalCount",

        //adult&child data
        "AdultnChildOverviewData",
        "AdultnChildHourlyData",
        "AdultnChildDailyData",
        "AdultnChildWeeklyData",
        "AdultnChildMonthlyData",
        "AdultnChildYearlyData",

        //genderData
        "GenderOverviewData",
        "GenderHourlyData",
        "GenderDailyData",
        "GenderWeeklyData",
        "GenderMonthlyData",
        "GenderYearlyData",

        //notifications
        "Notifications",

        //reports
        "ExtremeData",
        "MonthlyReportData",
        "AdultnChildExtremeData",
        "Adult&ChildMonthlyReportData",
        "UpdateSheet",
        "AdultnChildUpdateSheet",

        //companies
        "Companies",
        "TotalCompanies",
        "AddCompanies",
        "UpdateCompany",
        "Company",
        "AssignCompany",
        "RemoveCompany",

        //alternatives
        "Alternatives",
        "UpdateAlternative",
        "UpdateSiteAlternative",

        //permissions
        "Permissions",

        //roles
        "AddPermissionToUser",
        "RemovePermissionFromUser",

        //licenses
        "Licenses",
        "AddLisences",

        //packages
        "Package",
        "AddPackage",

        //addon
        "Addon",
        "AddonByDevice",
        "UpdateAddon"
    ],
    
    endpoints: (build) => ({
        
        //general
        login: build.mutation({ query: (data) => ({ url: "general/login", method: "POST", body: data, }), providesTags: ["Login"], }),
        signup: build.mutation({ query: (userData) => ({ url: "general/signUp", method: "POST", body: userData, }), providesTags: ["Signup"], }),
        getUser: build.query({ query: (userId) => `general/user/${userId}`, providesTags: ["User"], }),
        getAllUsers: build.query({ query: (company) => `general/getAllUsers/${company}`, providesTags: ["AllUser"], }),
        getRefresh: build.query({ query: () => "general/refresh", providesTags: ["Refresh"], refetchOnMountOrArgChange: true, }),
        logout: build.mutation({ query: (data) => ({ url: "general/logout", method: "POST", body: data, }), providesTags: ["Logout"], }),
        updateUser: build.mutation({ query: (userData) => ({ url: "general/update", method: "POST", body: userData, }), providesTags: ["UpdateUser"], }),
        addUser: build.mutation({ query: ({ name, email, company, role }) => ({ url: `general/addUser/`, method: "POST", body: { name, email, company, role } }), providesTags: ["AddUser"], }),
        deactivateUser: build.mutation({ query: (userId) => ({ url: `general/deactivateUser/${userId}`, method: "POST" }), providesTags: ["DeactivateUser"], }),
        activateUser: build.mutation({ query: (userId) => ({ url: `general/activateUser/${userId}`, method: "POST" }), providesTags: ["ActivateUser"], }),
        removeUser: build.mutation({ query: (userId) => ({ url: `general/removeUser/${userId}`, method: "POST" }), providesTags: ["RemoveUser"], }),
        editUser: build.mutation({ query: ({ userId, data }) => ({ url: `general/editUser/${userId}`, method: "POST", body: data, }), providesTags: ["EditUser"], }),
        changePassword: build.mutation({ query: ({ userId, oldPassword, newPassword }) => ({ url: `general/changePassword/${userId}`, method: "POST", body: { oldPassword, newPassword } }), providesTags: ["ChangePassword"], }),
        sendInvitation: build.mutation({ query: ({ name, email }) => ({ url: `general/sendInvitation`, method: "POST", body: { name, email } }), providesTags: ["SendInvitation"], }),

        //devices
        getDevices: build.query({ query: (company) => `device/devices/${company}`, providesTags: ["Devices"], }),
        getDeviceNames: build.query({ query: (company) => `device/deviceNames/${company}`, providesTags: ["Devices"], }),
        addDevice: build.mutation({ query: (data) => ({ url: "device/addDevice", method: "POST", body: data, }), providesTags: ["AddDevice"], }),
        removeDevice: build.mutation({ query: (data) => ({ url: "device/removeDevice", method: "POST", body: data, }), providesTags: ["removeDevice"], }),
        downtime: build.query({ query: (mac) => `device/downtime/${mac}`, provideTags: ["Downtime"]}),

        //sites
        getSites: build.query({ query: (company) => `site/site/${company}`, providesTags: ["Sites"], }),
        addSite: build.mutation({ query: (data) => ({ url: "site/addSite", method: "POST", body: data, }), providesTags: ["AddSite"], }),
        removeSite: build.mutation({ query: (siteId) => ({ url: `site/removeSite/${siteId}`, method: "POST" }), providesTags: ["RemoveSite"], }),
        addDeviceToSite: build.mutation({ query: (data) => ({ url: "site/addDeviceToSite", method: "POST", body: data, }), providesTags: ["AddDeviceToSite"], }),
        removeDeviceFromSite: build.mutation({ query: (data) => ({ url: "site/removeDeviceFromSite", method: "POST", body: data, }), providesTags: ["RemoveDeviceFromSite"], }),
        disableSite: build.mutation({ query: (siteId) => ({ url: `site/disableSite/${siteId}`, method: "POST" }), providesTags: ["DisableSite"], }),
        enableSite: build.mutation({ query: (siteId) => ({ url: `site/enableSite/${siteId}`, method: "POST" }), providesTags: ["EnableSite"], }),

        //logics
        getLogics: build.query({ query: () => "logic/logic", providesTags: ["Logics"], }),

        //data
        getData: build.query({ query: () => "data/data", providesTags: ["Data"], }),
        overviewData: build.query({ query: ({ mac }) => ({ url: `data/overviewData`, method: 'POST', body: { mac }, }), providesTags: ["OverviewData"], }),
        hourlyData: build.query({ query: ({ mac, startDate, endDate }) => ({ url: `data/hourlyData`, method: 'POST', body: { mac, startDate, endDate }, }), providesTags: ["HourlyData"], }),
        dailyData: build.query({ query: ({ mac }) => ({ url: `data/dailyData`, method: 'POST', body: { mac }, }), providesTags: ["DailyData"], }),
        weeklyData: build.query({ query: ({ mac }) => ({ url: `data/weeklyData`, method: 'POST', body: { mac }, }), providesTags: ["WeeklyData"], }),
        monthlyData: build.query({ query: ({ mac }) => ({ url: `data/monthlyData`, method: 'POST', body: { mac }, }), providesTags: ["MonthlyData"], }),
        yearlyData: build.query({ query: ({ mac }) => ({ url: `data/yearlyData`, method: 'POST', body: { mac }, }), providesTags: ["YearlyData"], }),
        totalData: build.query({ query: (company) => ({ url: `data/totalData`, method: 'POST', body: { company }, }), providesTags: ["TotalData"], }),

        //adult&childdata
        adultnChildOverviewData: build.query({ query: ({ mac }) => ({ url: `data/adult&ChildOverviewData`, method: 'POST', body: { mac }, }), providesTags: ["AdultnChildOverviewData"], }),
        adultnChildHourlyData: build.query({ query: ({ mac, startDate, endDate }) => ({ url: `data/adult&ChildHourlyData`, method: 'POST', body: { mac, startDate, endDate }, }), providesTags: ["AdultnChildHourlyData"], }),
        adultnChildDailyData: build.query({ query: ({ mac }) => ({ url: `data/adult&ChildDailyData`, method: 'POST', body: { mac }, }), providesTags: ["AdultnChildDailyData"], }),
        adultnChildWeeklyData: build.query({ query: ({ mac }) => ({ url: `data/adult&ChildWeeklyData`, method: 'POST', body: { mac }, }), providesTags: ["AdultnChildWeeklyData"], }),
        adultnChildMonthlyData: build.query({ query: ({ mac }) => ({ url: `data/adult&ChildMonthlyData`, method: 'POST', body: { mac }, }), providesTags: ["AdultnChildMonthlyData"], }),
        adultnChildYearlyData: build.query({ query: ({ mac }) => ({ url: `data/adult&ChildYearlyData`, method: 'POST', body: { mac }, }), providesTags: ["AdultnChildYearlyData"], }),

        //adult&childdata
        genderOverviewData: build.query({ query: ({ mac }) => ({ url: `data/genderOverviewData`, method: 'POST', body: { mac }, }), providesTags: ["GenderOverviewData"], }),
        genderHourlyData: build.query({ query: ({ mac, startDate, endDate }) => ({ url: `data/genderHourlyData`, method: 'POST', body: { mac, startDate, endDate }, }), providesTags: ["GenderHourlyData"], }),
        genderDailyData: build.query({ query: ({ mac }) => ({ url: `data/genderDailyData`, method: 'POST', body: { mac }, }), providesTags: ["GenderailyData"], }),
        genderWeeklyData: build.query({ query: ({ mac }) => ({ url: `data/genderWeeklyData`, method: 'POST', body: { mac }, }), providesTags: ["GenderWeeklyData"], }),
        genderMonthlyData: build.query({ query: ({ mac }) => ({ url: `data/genderMonthlyData`, method: 'POST', body: { mac }, }), providesTags: ["GenderMonthlyData"], }),
        genderYearlyData: build.query({ query: ({ mac }) => ({ url: `data/genderYearlyData`, method: 'POST', body: { mac }, }), providesTags: ["GenderYearlyData"], }),

        //notifications
        getNotifications: build.query({ query: () => "device/notifications", providesTags: ["Notifications"], }),

        //reports
        extremeData: build.query({ query: ({ mac, month, year }) => ({ url: `report/extremeData`, method: 'POST', body: { mac, month, year }, }), providesTags: ["ExtremeData"], }),
        monthlyReportData: build.query({ query: ({ mac, month, year }) => ({ url: `report/monthlyReportData`, method: 'POST', body: { mac, month, year }, }), providesTags: ["MonthlyReportData"], }),
        adultnChildExtremeData: build.query({ query: ({ mac, month, year }) => ({ url: `report/adult&ChildExtremeData`, method: 'POST', body: { mac, month, year }, }), providesTags: ["AdultnChildExtremeData"], }),
        adultnChildMonthlyReportData: build.query({ query: ({ mac, month, year }) => ({ url: `report/adult&ChildMonthlyReportData`, method: 'POST', body: { mac, month, year }, }), providesTags: ["AdultnChildMonthlyReportData"], }),
        updateSheet: build.mutation({ query: (data) => ({ url: "report/updateSheet", method: "POST", body: data, }), providesTags: ["UpdateSheet"], }),
        adultnChildUpdateSheet: build.mutation({ query: (data) => ({ url: "report/adult&ChildUpdateSheet", method: "POST", body: data, }), providesTags: ["AdultnChildUpdateSheet"], }),

        //companies
        getCompanies: build.query({ query: () => "company/companies", providesTags: ["Companies"], }),
        getTotalCompanies: build.query({ query: () => "company/companyCounts", providesTags: ["TotalCompanies"], }),
        addCompany: build.mutation({ query: (data) => ({ url: "company/addCompany", method: "POST", body: data, }), providesTags: ["AddCompany"], }),
        updateCompany: build.mutation({ query: ({ companyName, data }) => ({ url: `company/updateCompany/${companyName}`, method: "POST", body: data, }), providesTags: ["UpdateCompany"], }),
        getCompany: build.query({ query: (company) => `company/company/${company}`, providesTags: ["Company"], }),
        assignCompanyToPowerAdmin: build.mutation({ query: ({ userId, companyId }) => ({ url: `company/assignCompanyToPowerAdmin`, method: 'POST', body: { userId, companyId }, }), providesTags: ["AssignCompany"], }),
        removeCompanyFromPowerAdmin: build.mutation({ query: ({ userId, companyId }) => ({ url: `company/removeCompanyFromPowerAdmin`, method: 'POST', body: { userId, companyId }, }), providesTags: ["RemoveCompany"], }),

        //alternatives
        getAlternatives: build.query({ query: (company) => `alternative/getAlternative/${company}`, providesTags: ["Alternatives"], }),
        updateAlternative: build.mutation({ query: (data) => ({ url: "alternative/updateAlternative", method: "POST", body: data, }), providesTags: ["UpdateAlternative"], }),
        updateSiteAlternative: build.mutation({ query: (data) => ({ url: "alternative/updateSiteAlternative", method: "POST", body: data, }), providesTags: ["UpdateSiteAlternative"], }),

        //permissions
        getPermissions: build.query({ query: () => "permission/permissions", provideTags: ["Permisions"], }),

        //roles
        addPermissionToUser: build.mutation({ query: ({ userId, permissionId }) => ({ url: `role/addPermission`, method: 'POST', body: { userId, permissionId }, }), providesTags: ["AddPermissionToUser"], }),
        removePermissionFromUser: build.mutation({ query: ({ userId, permissionId }) => ({ url: `role/removePermission`, method: 'POST', body: { userId, permissionId }, }), providesTags: ["RemovePermissionFromUser"], }),

        //licenses
        getLicenses: build.query({ query: (company) => `license/licenses/${company}`, providesTags: ["Licenses"], }),
        addLicenses: build.mutation({ query: ({ deviceId, companyId, packageId }) => ({ url: `license/addLicenses`, method: 'POST', body: { deviceId, companyId, packageId }, }), providesTags: ["AddLicenses"], }),

        //packages
        getPackages: build.query({ query: () => "package/packages", providesTags: ["Packages"], }),
        addPackage: build.mutation({ query: ({ name, description, durationInDays }) => ({ url: `package/addPackage`, method: 'POST', body: { name, description, durationInDays }, }), providesTags: ["AddPackage"], }),

        //addon
        getAddons: build.query({ query: (company) => `addon/getAddons/${company}`, providesTags: ["Addon"], }),
        getAddonByDevice: build.query({ query: (mac) => `addon/getAddonsByDevice/${mac}`, providesTags: ["Addon"], }),
        updateAddon: build.mutation({ query: ({ addonId, data }) => ({ url: `addon/updateAddon/${addonId}`, method: "PUT", body: data, }), providesTags: ["UpdateAddon"], }),
    }),
});

export const {
    //general
    useLoginMutation,
    useSignupMutation,
    useGetUserQuery,
    useGetAllUsersQuery,
    useGetRefreshQuery,
    useLogoutMutation,
    useUpdateUserMutation,
    useAddUserMutation,
    useActivateUserMutation,
    useDeactivateUserMutation,
    useRemoveUserMutation,
    useEditUserMutation,
    useChangePasswordMutation,
    useSendInvitationMutation,

    //devices
    useGetDevicesQuery,
    useGetDeviceNamesQuery,
    useAddDeviceMutation,
    useRemoveDeviceMutation,
    useDowntimeQuery,

    //sites
    useGetSitesQuery,
    useAddSiteMutation,
    useRemoveSiteMutation,
    useAddDeviceToSiteMutation,
    useRemoveDeviceFromSiteMutation,
    useDisableSiteMutation,
    useEnableSiteMutation,

    //logics
    useGetLogicsQuery,

    //data
    useGetDataQuery,
    useOverviewDataQuery,
    useHourlyDataQuery,
    useDailyDataQuery,
    useWeeklyDataQuery,
    useMonthlyDataQuery,
    useYearlyDataQuery,
    useTotalDataQuery,

    //adult&childdata
    useAdultnChildOverviewDataQuery,
    useAdultnChildHourlyDataQuery,
    useAdultnChildDailyDataQuery,
    useAdultnChildWeeklyDataQuery,
    useAdultnChildMonthlyDataQuery,
    useAdultnChildYearlyDataQuery,

    //genderData
    useGenderOverviewDataQuery,
    useGenderHourlyDataQuery,
    useGenderDailyDataQuery,
    useGenderWeeklyDataQuery,
    useGenderMonthlyDataQuery,
    useGenderYearlyDataQuery,

    //notifications
    useGetNotificationsQuery,

    //reports
    useExtremeDataQuery,
    useMonthlyReportDataQuery,
    useAdultnChildExtremeDataQuery,
    useAdultnChildMonthlyReportDataQuery,
    useUpdateSheetMutation,
    useAdultnChildUpdateSheetMutation,

    //companies
    useGetCompaniesQuery,
    useGetTotalCompaniesQuery,
    useAddCompanyMutation,
    useUpdateCompanyMutation,
    useGetCompanyQuery,
    useAssignCompanyToPowerAdminMutation,
    useRemoveCompanyFromPowerAdminMutation,

    //alternatives
    useGetAlternativesQuery,
    useUpdateAlternativeMutation,
    useUpdateSiteAlternativeMutation,
    

    //permissions
    useGetPermissionsQuery,

    //roles
    useAddPermissionToUserMutation,
    useRemovePermissionFromUserMutation,

    //licenses
    useGetLicensesQuery,
    useAddLicensesMutation,

    //packages
    useGetPackagesQuery,
    useAddPackageMutation,

    //addon
    useGetAddonsQuery,
    useGetAddonByDeviceQuery,
    useUpdateAddonMutation,
} = api;