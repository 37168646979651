import React, { useState } from 'react';
import { Box, Grid, Paper } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { useOverviewDataQuery, useAdultnChildOverviewDataQuery, useGenderOverviewDataQuery, useGetAddonByDeviceQuery } from '../../services/api';
import { useSelector } from 'react-redux';
import OverviewHeader from './OverviewHeader';
import OverviewContent from './OverviewContent';
import useSelectedSensor from '../../hooks/useSelectedSensor';
import useSensorOptions from '../../hooks/useSensorOptions';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import OverviewFilterBox from './OverviewFilterBox';
import OverviewLabel from './OverviewLabel';

const Overview = () => {
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });
    const [tabValue, setTabValue] = useState(0);
    const { role, company } = useSelector(state => state.global);

    const { companyOptions } = useCompanyOptions();

    const isSuperOrPowerAdmin = role === 'Super Admin' || role === 'Power Admin';

    const { selectedCompany, handleCompanyChange } = useSelectedCompany({ companyOptions });
    const sensorOptions = useSensorOptions(isSuperOrPowerAdmin ? selectedCompany : company);
    const { selectedSensor, handleSensorChange, getSelectedSensorLabel } = useSelectedSensor(sensorOptions, isSuperOrPowerAdmin ? selectedCompany : company);

    // Fetch addons for the device
    const { data: addonData } = useGetAddonByDeviceQuery(selectedSensor);

    const availableTabs = [
        { label: "TOTAL", value: 0 },
        addonData?.[0]?.adultnchild && { label: "ADULT & CHILD", value: 1 },
        addonData?.[0]?.gender && { label: "GENDER", value: 2 },
        addonData?.[0]?.mask && { label: "MASK", value: 3 },
    ].filter(Boolean); // Filter out undefined values

    // Call queries unconditionally
    const { data: hourlyData, isLoading: hourlyLoading, error: hourlyError } = useOverviewDataQuery({ mac: selectedSensor });
    const { data: adultChildData, isLoading: adultChildLoading, error: adultChildError } = useAdultnChildOverviewDataQuery({ mac: selectedSensor });
    const { data: genderData, isLoading: genderLoading, error: genderError } = useGenderOverviewDataQuery({ mac: selectedSensor });

    // Determine which data to use based on the selected tab
    const overviewData = availableTabs[tabValue]?.value === 0 ? hourlyData 
                        : availableTabs[tabValue]?.value === 1 ? adultChildData 
                        : availableTabs[tabValue]?.value === 2 ? genderData 
                        : null; // Set to null if the tab is invalid

    const overviewLoading = availableTabs[tabValue]?.value === 0 ? hourlyLoading 
                            : availableTabs[tabValue]?.value === 1 ? adultChildLoading 
                            : availableTabs[tabValue]?.value === 2 ? genderLoading 
                            : null; // Set to null if the tab is invalid

    const error = availableTabs[tabValue]?.value === 0 ? hourlyError 
                : availableTabs[tabValue]?.value === 1 ? adultChildError 
                : availableTabs[tabValue]?.value === 2 ? genderError 
                : null; // Set to null if the tab is invalid

    const handleTabChange = (newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box m={isSmallScreen ? "0.5rem" : "1rem 2.5rem"}>
            <Grid container spacing={2} paddingBottom={3}>
                <Grid item xs={12} md={10}>
                    <OverviewHeader isSmallScreen={isSmallScreen} />
                    <OverviewLabel
                        sensorLabel={getSelectedSensorLabel()}
                        isSmallScreen={isSmallScreen}
                        tabValue={tabValue}
                        onTabChange={handleTabChange}
                        addonData={addonData}
                    />
                    <OverviewContent
                        selectedSensor={selectedSensor}
                        overviewData={overviewData}
                        overviewLoading={overviewLoading}
                        error={error}
                        isSmallScreen={isSmallScreen}
                        sensorLabel={getSelectedSensorLabel()}
                        tabValue={tabValue}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Paper sx={{ position: 'sticky', top: 20, height: 'auto', overflow: 'auto', borderRadius: '15px' }}>
                        <OverviewFilterBox
                            sensorLabel={getSelectedSensorLabel()}
                            isSmallScreen={isSmallScreen}
                            sensorOptions={sensorOptions}
                            selectedSensor={selectedSensor}
                            handleSensorChange={handleSensorChange}
                            companyOptions={companyOptions}
                            selectedCompany={isSuperOrPowerAdmin ? selectedCompany : null}
                            handleCompanyChange={isSuperOrPowerAdmin ? handleCompanyChange : null}
                            isSuperAdmin={isSuperOrPowerAdmin}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Overview;