import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import DashboardOverviewChart from '../../charts/dashboardCharts/DashboardOverviewChart';
import { useMediaQuery } from 'react-responsive';

const OverviewChart = ({ data, isLoading }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery({ maxWidth: 480 });

    return (
        <Box
            gridColumn={isSmallScreen ? "span 12" : "span 8"}
            gridRow="span 3"
            backgroundColor={theme.palette.background.paper}
            borderRadius="0.55rem"
            display="flex"
            flexDirection="column"
            position="relative"
        >
            <Typography variant='h3' sx={{ color: theme.palette.secondary.main, mt: "2%", ml: "2%", fontWeight: "bold" }}>
                OVERVIEW
            </Typography>
            <Box
                sx={{
                    height: "calc(100% - 50px)",
                    width: "100%",
                    position: "relative",
                }}
            >
                <DashboardOverviewChart overviewData={data} overviewLoading={isLoading} />
            </Box>
        </Box>
    )
}

export default OverviewChart;
