import React from 'react';
import { Button, useTheme } from '@mui/material';

const GenerateReport = ({ loading, showPdfButton, pdfGenerated, handleDownloadPdfButton, handlePdfButtonClick }) => {
    const theme = useTheme();

    return (
        <>
            {loading ? (
                <Button
                    disabled
                    style={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.text.black,
                        borderColor: '#bbbdbd',
                        height: '52px',
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.main,
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                        fontWeight: 'bold',
                    }}
                >
                    Generating Report...
                </Button>
            ) : (
                <>
                    {showPdfButton && pdfGenerated && (
                        <Button
                            variant="outlined"
                            size="large"
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.text.black,
                                borderColor: '#bbbdbd',
                                height: '52px',
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                },
                                '&:focus': {
                                    outline: 'none',
                                },
                                fontWeight: 'bold',
                            }}        
                            onClick={handleDownloadPdfButton}
                        >
                            Download PDF
                        </Button>
                    )}
                    {!showPdfButton && !pdfGenerated && (
                        <Button
                            variant="outlined"
                            size="large"
                            style={{
                                color: theme.palette.text.white,
                                borderColor: '#bbbdbd',
                                height: '52px',
                            }}
                            onClick={handlePdfButtonClick}
                        >
                            Generate PDF
                        </Button>
                    )}
                </>
            )}
        </>
    );
};

export default GenerateReport;
